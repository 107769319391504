import { message } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Nav, Row, Tab } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_ADM_WP_PROPERTY, SET_PAGE_LOADING } from "~/redux/type"
import ConfigSurgicalAuth from "./surgical_auth/ConfigSurgicalAuth"
import ConfigEclaimUc from "./configEclaim_uc/ConfigEclaimUc"
import ConfigPharmacy from "./pharmacy/ConfigPharmacy"
class ConfigWorkflowProperty extends React.Component {
	EConfigWP = {
		SURGICAL_AUTH: "SURGICAL_AUTH",
		ECLAIM_UC: "ECLAIM_UC",
		PHARMACY: "PHARMACY",
	}

	constructor(props) {
		super(props)

		this.state = {
			tab: this.EConfigWP.SURGICAL_AUTH,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadProperties = this.loadProperties.bind(this)
		this.onDone = this.onDone.bind(this)
	}
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.tab, this.state.tab)) {
			this.props.setValue(SET_ADM_WP_PROPERTY, null)
		}
	}

	loadProperties(url, func = () => {}) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res)
		}
		let error = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onDone(url, data) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.workflowProperties)) {
				this.props.setValue(SET_ADM_WP_PROPERTY, res.data.workflowProperties[0])
				message.success(this.props.intl.formatMessage({ id: ResourceAssistance.Message.success }), 2)
			} else {
				message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.failed }), 2)
			}
		}
		let error = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Row>
								<Col>
									<Nav variant="tabs" fill>
										<Nav.Item>
											<Nav.Link eventKey={this.EConfigWP.SURGICAL_AUTH}>{translate(ResourceAssistance.Message.surgicalAuthorization)}</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey={this.EConfigWP.ECLAIM_UC}>{translate(ResourceAssistance.Message.eclaimUc)}</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link eventKey={this.EConfigWP.PHARMACY}>{translate(ResourceAssistance.Message.pharmacy)}</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
							</Row>
							<Row className="full-size">
								<Col>
									<Tab.Content>
										<Tab.Pane eventKey={this.EConfigWP.SURGICAL_AUTH}>
											{this.state.tab === this.EConfigWP.SURGICAL_AUTH && <ConfigSurgicalAuth loadProperties={this.loadProperties} onDone={this.onDone} />}
										</Tab.Pane>
										<Tab.Pane eventKey={this.EConfigWP.ECLAIM_UC}>
											{this.state.tab === this.EConfigWP.ECLAIM_UC && <ConfigEclaimUc loadProperties={this.loadProperties} onDone={this.onDone} />}
										</Tab.Pane>
										<Tab.Pane eventKey={this.EConfigWP.PHARMACY}>
											{this.state.tab === this.EConfigWP.PHARMACY && <ConfigPharmacy loadProperties={this.loadProperties} onDone={this.onDone} />}
										</Tab.Pane>
										ConfigPharmacy
									</Tab.Content>
								</Col>
							</Row>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigWorkflowProperty))
