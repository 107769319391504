import { Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_PV_MODAL_NEW_DUE_DATE_TIME, SET_PV_MODAL_NEW_REMARK, SET_PV_MODAL_NEW_SELECTED_PAYMENT, SET_PV_MODAL_NEW_SELECTED_SUPPLIER } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class PVModalNewFields extends React.Component {
	constructor(props) {
		super(props)

		this.onDueDate = this.onDueDate.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.PV.selectedPV.PV) {
			let selectedPV = this.props.PV.selectedPV.PV
			this.props.setValue(SET_PV_MODAL_NEW_DUE_DATE_TIME, selectedPV.paymentTermDateTime)
			this.props.setValue(SET_PV_MODAL_NEW_REMARK, selectedPV.remark)
			if (!_.isEmpty(this.props.PV.suppliers)) {
				this.initialSupplier()
			}
			if (!_.isEmpty(this.props.PV.paymentTypes)) {
				this.initialPayment()
			}
		}
		console.log(this.props.PV)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PV.suppliers, this.props.PV.suppliers)) {
			if (this.props.PV.selectedPV.PV && !_.isEmpty(this.props.PV.suppliers)) {
				this.initialSupplier()
			}
		}
		if (!_.isEqual(prevProps.PV.paymentTypes, this.props.PV.paymentTypes)) {
			if (this.props.PV.selectedPV.PV && !_.isEmpty(this.props.PV.paymentTypes)) {
				this.initialPayment()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_SUPPLIER, -1, null)
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_PV_MODAL_NEW_DUE_DATE_TIME, "")
		this.props.setValue(SET_PV_MODAL_NEW_REMARK, "")
	}

	initialSupplier() {
		let idx = this.props.PV.suppliers.findIndex((each) => each.code === this.props.PV.selectedPV.PV.supplierCode)
		if (idx > -1) {
			this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_SUPPLIER, idx, this.props.PV.suppliers[idx])
		}
	}

	initialPayment() {
		let idx = this.props.PV.paymentTypes.findIndex((each) => each.displayName === this.props.PV.selectedPV.PV.payment)
		if (idx > -1) {
			this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PAYMENT, idx, this.props.PV.paymentTypes[idx])
		}
	}

	onDueDate(v) {
		this.props.setValue(SET_PV_MODAL_NEW_DUE_DATE_TIME, moment(v).endOf("day").milliseconds(0).valueOf())
	}

	onSupplier(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_SUPPLIER, -1, null)
			return
		}
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_SUPPLIER, value, this.props.PV.suppliers[value])
	}

	onPayment(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PAYMENT, -1, null)
			return
		}
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PAYMENT, value, this.props.PV.paymentTypes[value])
	}

	onRemark(e) {
		this.props.setValue(SET_PV_MODAL_NEW_REMARK, e.target.value)
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2}>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.dueDate })]}
							status={this.props.PV.modalNew.dueDateTime ? undefined : "error"}
							values={this.props.PV.modalNew.dueDateTime ? [moment(this.props.PV.modalNew.dueDateTime)] : [undefined]}
							onChange={this.onDueDate}
						/>
					</Col>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.supplier)}
							loading={this.props.PV.isSuppliersLoading}
							status={this.props.PV.modalNew.selectedSupplier.supplier ? undefined : "error"}
							value={this.props.PV.modalNew.selectedSupplier.supplier ? this.props.PV.modalNew.selectedSupplier.index : undefined}
							onChange={this.onSupplier}
						>
							{Utils.renderSelects(this.props.PV.suppliers, false, -1, "displayCodeName")}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col md={3} style={{ justifyContent: "flex-start" }}>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.payment)}
							loading={this.props.PV.isPaymentTypesLoading}
							status={this.props.PV.modalNew.selectedPayment.payment ? undefined : "error"}
							value={this.props.PV.modalNew.selectedPayment.payment ? this.props.PV.modalNew.selectedPayment.index : undefined}
							onChange={this.onPayment}
						>
							{Utils.renderSelects(this.props.PV.paymentTypes, false)}
						</Select>
					</Col>
					<Col>
						<GInput
							autoSize
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark })}
							minRows={5}
							onChange={this.onRemark}
							value={this.props.PV.modalNew.remark}
						/>
					</Col>
				</Row>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PVModalNewFields))
