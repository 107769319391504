import _ from "lodash"
import React from "react"
import { Col, Image, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import io from "socket.io-client"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_DOB,
	SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE,
	SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE,
	SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER,
	SET_HOSPITEL_IPD_MODAL_REGISTER_NATION,
	SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TEL,
	SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE,
	SET_PAGE_LOADING,
	SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG,
	SET_REGISTRATION_MODAL_REGISTER_PHOTO,
	SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY,
	SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY,
	SET_REGISTRATION_MODAL_REGISTER_RACE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import GInput from "~/view/component/input/GInput"
import { genders, nameTitles } from "./IPDModalRegisterDetails"
import ServerUtils from "./../../../../../utils/ServerUtils"
import ASelect from "~/view/component/ant_select/ASelect"

const Property = {
	nameTitle: "NAME_TITLE",
	firstName: "FIRST_NAME",
	lastName: "LAST_NAME",
	firstNameEng: "FIRST_NAME_ENG",
	lastNameEng: "LAST_NAME_ENG",
	gender: "GENDER",
	identificationNumber: "IDENTIFICATION_NUMBER",
	race: "race",
	idType: "IdType",
	marriage: "marriage",
	career: "career",
	nation: "nation",
	dob: "DOB",
	telPrimary: "TEL_PRIMARY",
	telSecondary: "TEL_SECONDARY",
	telTertiary: "TEL_TERTIARY",
	address: "ADDRESS",
	relFirstName: "RELATIVE_INFO_FIRST_NAME",
	relLastName: "RELATIVE_INFO_LAST_NAME",
	relRelationship: "RELATIVE_INFO_RELATIONSHIP",
	relTel: "RELATIVE_INFO_TEL",
	relAddress: "RELATIVE_INFO_ADDRESS",
}

class IPDModalRegisterInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			socket: null,
		}

		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onSelectChange = this.onSelectChange.bind(this)
	}

	componentDidMount() {
		this.connectSMCReader()

		if (this.props.ipd.selectedPatient.index !== -1) {
			const patient = this.props.ipd.selectedPatient.patient
			const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, patient.title))
			const genderIdx = genders.findIndex((gender) => _.isEqual(gender.displayName, patient.gender))
			const idTypeIdx = this.props.ipd.modalRegister.idTypeOptions.findIndex((item) => _.isEqual(item.name, patient.idType))
			const marriageIdx = this.props.ipd.modalRegister.marriageOptions.findIndex((item) => _.isEqual(item.name, patient.marriage))
			const careerIdx = this.props.ipd.modalRegister.careerOptions.findIndex((item) => _.isEqual(item.name, patient.career))
			const nationIdx = this.props.ipd.modalRegister.nationOptions.findIndex((item) => _.isEqual(item.name, patient.nation))

			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, titleIdx > -1 ? nameTitles[titleIdx].displayName : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, patient.firstName)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, patient.lastName)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, patient.firstNameEng)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, patient.lastNameEng)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, genderIdx > -1 ? genders[genderIdx].displayName : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, patient.identificationNumber)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE, idTypeIdx > -1 ? this.props.ipd.modalRegister.idTypeOptions[idTypeIdx].name : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE, marriageIdx > -1 ? this.props.ipd.modalRegister.marriageOptions[marriageIdx].name : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER, careerIdx > -1 ? this.props.ipd.modalRegister.careerOptions[careerIdx].name : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION, nationIdx > -1 ? this.props.ipd.modalRegister.nationOptions[nationIdx].name : "")
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, patient.dobtime)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, patient.tel)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, patient.telSecondary)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, patient.telTertiary)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, patient.address)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, patient.photo)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RACE, patient.race)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ipd.modalRegister.idTypeOptions, this.props.ipd.modalRegister.idTypeOptions)) {
			this.setSelectByselectedPatient(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE, "idType", this.props.ipd.modalRegister.idTypeOptions)
		}
		if (!_.isEqual(prevProps.ipd.modalRegister.marriageOptions, this.props.ipd.modalRegister.marriageOptions)) {
			this.setSelectByselectedPatient(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE, "marriage", this.props.ipd.modalRegister.marriageOptions)
		}
		if (!_.isEqual(prevProps.ipd.modalRegister.careerOptions, this.props.ipd.modalRegister.careerOptions)) {
			this.setSelectByselectedPatient(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER, "career", this.props.ipd.modalRegister.careerOptions)
		}
		if (!_.isEqual(prevProps.ipd.modalRegister.nationOptions, this.props.ipd.modalRegister.nationOptions)) {
			this.setSelectByselectedPatient(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION, "nation", this.props.ipd.modalRegister.nationOptions)
		}
	}

	componentWillUnmount() {
		if (this.state.socket) {
			this.state.socket.disconnect()
		}
		this.reset()
	}

	setSelectByselectedPatient(type, optionKey, options) {
		const selectedPatient = this.props.ipd.selectedPatient
		if (selectedPatient.index !== -1 && options.length !== 0) {
			const selectIdx = options.findIndex((item) => _.isEqual(item.name, selectedPatient.patient[optionKey]))
			this.props.setValue(type, selectIdx > -1 ? options[selectIdx].name : "")
		}
	}

	reset() {
		this.resetSmartCardInfo()
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RACE, "")
	}

	resetSmartCardInfo() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, "")
	}

	connectSMCReader() {
		const socket = io.connect("http://127.0.0.1:9898")
		this.setState({ socket: socket })
		socket.on("connect_error", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Hospitel.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect_failed", () => {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Message.smartCardReaderIsNotWorking,
				})}`
			)
			socket.disconnect()
		})
		socket.on("connect", function () {
			socket.emit("set-query", {
				query: ["cid", "name", "nameEn", "dob", "gender", "address", "photo"],
			})
		})
		socket.on("smc-data", (res) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
			if (res.data.name && res.data.name.prefix) {
				const titleIdx = nameTitles.findIndex((title) => _.isEqual(title.displayName, res.data.name.prefix))
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, titleIdx > -1 ? nameTitles[titleIdx].displayName : "")
			}

			if (res.data.gender) {
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, genders[Utils.BigNumber(res.data.gender).minus(1).toNumber()].displayName)
			}

			if (res.data) {
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, res.data.cid || "")
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, res.data.address && res.data.address.full ? res.data.address.full : "")
				this.props.setValue(
					SET_HOSPITEL_IPD_MODAL_REGISTER_DOB,
					res.data.dob ? Utils.generateDateByFormat(res.data.dob, "yyyy-mm-dd").getTime() : Utils.generateDate().getTime()
				)
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_PHOTO, res.data.photo)
			}

			if (res.data && res.data.name) {
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, res.data.name.firstname || "")
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, res.data.name.lastname || "")
			}

			if (res.data && res.data.nameEN) {
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, res.data.nameEN.firstname || "")
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, res.data.nameEN.lastname || "")
			}
		})

		socket.on("smc-removed", function (data) {})
		socket.on("smc-incorrect", function (data) {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		})
		socket.on("smc-inserted", (data) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
			this.resetSmartCardInfo()
		})
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.firstName:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_FIRST_NAME, event.target.value)
				break
			case Property.lastName:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_LAST_NAME, event.target.value)
				break
			case Property.firstNameEng:
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_FIRST_NAME_ENG, event.target.value)
				break
			case Property.lastNameEng:
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_LAST_NAME_ENG, event.target.value)
				break
			case Property.identificationNumber:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDENTIFICATION_NUMBER, Utils.trim(event.target.value))
				break
			case Property.telPrimary:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TEL, event.target.value)
				break
			case Property.telSecondary:
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_SECONDARY, event.target.value)
				break
			case Property.telTertiary:
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_TEL_TERTIARY, event.target.value)
				break
			case Property.address:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ADDRESS, event.target.value)
				break
			case Property.race:
				this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RACE, event.target.value)
				break
			default:
				break
		}
	}

	onSelectChange(name, value) {
		switch (name) {
			case Property.nameTitle:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_TITLE, value)
				break
			case Property.gender:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_GENDER, value)
				break
			case Property.dob:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_DOB, value.getTime())
				break
			case Property.idType:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE, value)
				break
			case Property.marriage:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE, value)
				break
			case Property.career:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER, value)
				break
			case Property.nation:
				this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION, value)
				break
			default:
				break
		}
	}

	render() {
		return (
			<fieldset className={"space-top-each-row-parent"}>
				<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
				{this.props.ipd.modalRegister.photo && (
					<Row>
						<Col style={{ alignItems: "center" }}>
							<Image width={100} src={ServerUtils.getBase64Img(this.props.ipd.modalRegister.photo)} />
						</Col>
					</Row>
				)}
				<Row>
					<Col md={3}>
						<ASelect
							required
							showSearch
							style={{ flexGrow: 1, textAlign: "center" }}
							size={ResourceAssistance.Select.size.large}
							placeholder={translate(ResourceAssistance.Hospitel.title)}
							value={this.props.ipd.modalRegister.title}
							onSelectChange={(value) => this.onSelectChange(Property.nameTitle, value)}
							options={nameTitles}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.firstName}
							placeholder={translate(ResourceAssistance.Message.firstName)}
							value={this.props.ipd.modalRegister.firstName}
							onChange={this.onPropertyChange}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.lastName}
							placeholder={translate(ResourceAssistance.Message.lastName)}
							value={this.props.ipd.modalRegister.lastName}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3} />
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							name={Property.firstNameEng}
							placeholder={translate(ResourceAssistance.Message.firstNameEng)}
							value={this.props.ipd.modalRegister.firstNameEng}
							onChange={this.onPropertyChange}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							name={Property.lastNameEng}
							placeholder={translate(ResourceAssistance.Message.lastNameEng)}
							value={this.props.ipd.modalRegister.lastNameEng}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<ASelect
							required
							showSearch
							style={{ flexGrow: 1, textAlign: "center" }}
							size={"large"}
							placeholder={translate(ResourceAssistance.Hospitel.gender)}
							value={this.props.ipd.modalRegister.gender}
							onSelectChange={(value) => this.onSelectChange(Property.gender, value)}
							options={genders}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.identificationNumber}
							placeholder={translate(ResourceAssistance.Hospitel.identification)}
							value={this.props.ipd.modalRegister.identificationNumber}
							onChange={this.onPropertyChange}
						/>
					</Col>
					<Col>
						<ASelect
							required
							showSearch
							placeholder={translate(ResourceAssistance.Message.idType)}
							size={ResourceAssistance.Select.size.large}
							value={this.props.ipd.modalRegister.idType}
							onSelectChange={(value) => this.onSelectChange(Property.idType, value)}
							options={this.props.ipd.modalRegister.idTypeOptions}
							displayProperty="name"
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<ASelect
							required
							showSearch
							placeholder={translate(ResourceAssistance.Message.marriage)}
							size={ResourceAssistance.Select.size.large}
							value={this.props.ipd.modalRegister.marriage}
							onSelectChange={(value) => this.onSelectChange(Property.marriage, value)}
							options={this.props.ipd.modalRegister.marriageOptions}
							displayProperty="name"
						/>
					</Col>
					<Col>
						<ASelect
							required
							showSearch
							placeholder={translate(ResourceAssistance.Message.career)}
							size={ResourceAssistance.Select.size.large}
							value={this.props.ipd.modalRegister.career}
							onSelectChange={(value) => this.onSelectChange(Property.career, value)}
							options={this.props.ipd.modalRegister.careerOptions}
							displayProperty="name"
						/>
					</Col>
					<Col>
						<ASelect
							required
							showSearch
							placeholder={translate(ResourceAssistance.Message.nation)}
							size={ResourceAssistance.Select.size.large}
							value={this.props.ipd.modalRegister.nation}
							onSelectChange={(value) => this.onSelectChange(Property.nation, value)}
							options={this.props.ipd.modalRegister.nationOptions}
							displayProperty="name"
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.race}
							placeholder={translate(ResourceAssistance.Message.race)}
							value={this.props.ipd.modalRegister.race}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Hospitel.dateOfBirth)}</InputGroup.Text>
							<DatePicker
								inputClassName={ResourceAssistance.ID.HOSPITEL.ipd.modalRegister.dob}
								maximumDate={Utils.generateDate(0, 0, -1)}
								isShowTime={false}
								startDate={Utils.generateDateFromLong(this.props.ipd.modalRegister.dob)}
								locale={{ yearOffSet: 543 }}
								onChange={(date) => this.onSelectChange(Property.dob, date)}
							/>
							<InputGroup.Text>{translate(ResourceAssistance.Hospitel.age)}</InputGroup.Text>
							<InputGroup.Text>{Utils.calculateAge(this.props.ipd.modalRegister.dob)}</InputGroup.Text>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.telPrimary}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.tel}
							onChange={this.onPropertyChange}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							name={Property.telSecondary}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.telSecondary}
							onChange={this.onPropertyChange}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							name={Property.telTertiary}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							value={this.props.ipd.modalRegister.telTertiary}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							name={Property.address}
							placeholder={translate(ResourceAssistance.Message.address)}
							value={this.props.ipd.modalRegister.address}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setObjArray,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalRegisterInfo))
