import { Checkbox } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_WP_PHARMACY_DISPLAY_TITLE_IND, SET_ADM_WP_PROPERTY } from "~/redux/type"

class ConfigPharmacy extends React.Component {
	constructor(props) {
		super(props)

		this.onDisplayTitle = this.onDisplayTitle.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.props.loadProperties(ResourceAssistance.Url.admin.workflowProperty.getPharmacyProperties, (res) => {
			if (!_.isEmpty(res.data.workflowProperties)) {
				let property = res.data.workflowProperties[0]
				this.props.setValue(SET_ADM_WP_PROPERTY, property)
				this.props.setValue(SET_ADM_WP_PHARMACY_DISPLAY_TITLE_IND, property.displayTitle)
			}
		})
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_WP_PHARMACY_DISPLAY_TITLE_IND, undefined)
	}

	componentDidUpdate(prevProps, prevState) {}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.wp.property, {
			displayTitle: this.props.pharmacy.isDisplayTitle,
		})
		return _.isEqual(target, this.props.wp.property)
	}

	onDisplayTitle(e) {
		this.props.setValue(SET_ADM_WP_PHARMACY_DISPLAY_TITLE_IND, e.target.checked)
	}

	onDone(e) {
		e.preventDefault()
		e.stopPropagation()
		this.props.onDone(`${ResourceAssistance.Url.admin.workflowProperty.saveWorkflowProperty}?type=PHARMACY`, {
			id: this.props.wp.property ? this.props.wp.property.id : 0,
			userId: this.props.login.user.id,
			displayTitle: this.props.pharmacy.isDisplayTitle,
		})
	}

	render() {
		return (
			<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
				<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
					<Row>
						<Col md={"auto"}>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.pharmacy.isDisplayTitle} onChange={this.onDisplayTitle}>
								{translate(ResourceAssistance.Message.labelDisplayTitle)}
							</Checkbox>
						</Col>
						<Col md={"auto"}>
							<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
								<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.save)}
							</Button>
						</Col>
					</Row>
				</Container>
			</Form>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	wp: state.admin.configWP,
	pharmacy: state.admin.configWP.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigPharmacy)
