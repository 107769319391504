import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_PHARMACY_LOCATION_DISPLAY, SET_PHARMACY_WP, SET_PHARMACY_WP_LOADING } from "~/redux/type"
import PharmacyLeft from "./left/PharmacyLeft"
import PharmacyRight from "./right/PharmacyRight"

class Pharmacy extends React.Component {
	componentDidMount() {
		this.props.onModalDisplayAction(SET_PHARMACY_LOCATION_DISPLAY, true)
	}
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.location, this.props.pharmacy.location)) {
			if (this.props.pharmacy.location) {
				this.loadPharmacyWP()
			}
		}
	}
	loadPharmacyWP() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.workflowProperty.getPharmacyProperties,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.workflowProperties)) {
				let property = res.data.workflowProperties[0]
				this.props.setValue(SET_PHARMACY_WP, property)
			}
		}
		let error = (error) => {
			this.props.setLoadingAction(SET_PHARMACY_WP_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PHARMACY_WP_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PHARMACY_WP_LOADING, false)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Fragment>
				{this.props.pharmacy.location && (
					<Container fluid className={ResourceAssistance.CSS.fullFlex}>
						<Row className={"g-0 full-size"}>
							<Col style={{ maxWidth: "437px" }}>
								<PharmacyLeft />
							</Col>
							<Col>
								<PharmacyRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Pharmacy))
