const Url = {
	Url: {
		auth: {
			login: "/auth/login",
			logout: "/auth/logout",
			validateCookie: "/auth/validate",
		},
		authorization: {
			getWitness: "/authorization/gw",
		},
		pr: {
			getPrs: "/pr/getPrs",
			getTypes: "/getTypes",
			getLocations: "/pr/getLocations",
			savePR: "/savePr",
			updatePR: "/updatePr",
			deletePrItem: "/deletePrItem",
			setOrderItemSelection: "/setOIS",
		},

		po: {
			downloadFile: "/po/downloadFile",
			getPaymentTypes: "/po/getPaymentTypes",
			getPurchaseOrders: "/po/getPurchaseOrders",
			rejectPr: "/po/updatePrAndDeletePo",
			updatePoApproval: "/po/updatePoApproval",
			updatePoDetails: "/po/updatePoDetails",
			updatePoItems: "/po/updatePoItems",
			uploadAttachmentFile: "/po/uploadAttachment",
		},

		rc: {
			getReceives: "/rc/getReceives",
			cancelReceiveItems: "/rc/cancelReceiveItems",
			updateReceive: "/rc/updateReceive",
			saveReceiveItems: "/rc/saveReceiveItems",
		},

		transfer: {
			approve: "/tf/approve",
			completeTR: "/tf/completeTR",
			cancelTRI: "/tf/cancelTRI",
			createT: "/tf/createT",
			createTR: "/tf/createTR",
			getItems: "/tf/getItems",
			getOrgs: "/tf/getOrgs",
			getGeneralLocations: "/tf/getGenLocs",
			getRequests: "/tf/getRequests",
			getTypes: "/tf/getTypes",
			setTransferred: "tf/setTransferred",
			deleteRequestItem: "/tf/dri",
		},

		inventory: {
			deleteInventory: "/inv/deleteInventoryDetail",
			getActiveItems: "/inv/getActiveItems",
			getLocations: "/inv/getLocations",
			getTypes: "/inv/getTypes",
			saveInventory: "/inv/saveInventoryDetail",
		},

		admin: {
			item: {
				loadItemSuppliers: "admin/getItemSuppliers",
				saveItemSupplier: "admin/saveItemSupplier",
				saveUnitConversion: "/admin/item/suc",
				deleteUnitConversion: "/admin/item/duc",
			},
			itemSource: {
				loadItems: "admin/getItems",
				saveItem: "admin/saveItem",
			},
			location: {
				loadLocs: "admin/getLocations",
				saveLoc: "admin/saveLoc",
			},
			supplier: {
				loadSuppliers: "admin/getSuppliers",
				saveSupplier: "admin/saveSupplier",
			},
			type: {
				saveReceivableActivities: "/admin/category/sras",
				savePayableActivities: "/admin/category/spas",
				deleteAccountingActivities: "/admin/category/daas",
				anesthesia: {
					loadTypes: "admin/gans",
					saveType: "admin/san",
				},
				item: {
					loadTypes: "admin/getItemTypes",
					saveType: "admin/saveItemType",
					getChartOfAccounts: "/admin/gcoas",
				},
				payment: {
					loadTypes: "/admin/getPaymentTypes",
					saveType: "/admin/savePaymentType",
				},
				service: {
					loadTypes: "/admin/gsts",
					saveType: "/admin/sst",
				},
				role: {
					loadTypes: "/admin/grts",
					saveType: "/admin/srt",
				},
				default: {
					loadTypes: "/admin/gdts",
					saveType: "/admin/sdt",
				},
				healthCarePlan: {
					loadTypes: "/admin/ghcps",
					saveType: "/admin/shcp",
					deleteHealthCarePlanCompany: "/admin/healthcarePlan/dhcpc",
					deleteHealthCarePlanAccountsReceivable: "/admin/healthcarePlan/dhcpar",
					saveHealthCarePlanCompany: "/admin/healthcarePlan/shcpc",
					saveHealthCarePlanAccountsReceivable: "/admin/healthcarePlan/shcpar",
				},
				icd: {
					loadTypes: "/admin/gicds",
					saveType: "/admin/sicd",
				},
				iPay: {
					loadTypes: "/admin/gintps",
					saveType: "/admin/sintp",
				},
				isolationPrecaution: {
					loadTypes: "/admin/gips",
					saveType: "/admin/sip",
				},
				fallRisk: {
					loadTypes: "/admin/gfrs",
					saveType: "/admin/sfr",
				},
				intake: {
					loadTypes: "/admin/gis",
					saveType: "/admin/si",
				},
				output: {
					loadTypes: "/admin/gops",
					saveType: "/admin/sop",
				},
				operatingRoomCase: {
					loadTypes: "/admin/gorcs",
					saveType: "/admin/sorc",
				},
				pcr: {
					loadTypes: "/admin/gpcrs",
					saveType: "/admin/spcr",
				},
				antigen: {
					loadTypes: "/admin/gas",
					saveType: "/admin/sa",
				},
				insuranceCompany: {
					loadTypes: "/admin/gics",
					saveType: "/admin/sic",
				},
				bank: {
					loadTypes: "/admin/gbs",
					saveType: "/admin/sb",
					deleteBranch: "/admin/bank/dbb",
					saveBranch: "/admin/bank/sbb",
				},
				withholdingTax: {
					loadTypes: "/admin/gwhts",
					saveType: "/admin/swht",
				},
				glDocument: {
					loadTypes: "/admin/gglds",
					saveType: "/admin/sgld",
				},
			},
			unit: {
				loadUnits: "admin/getUnits",
				saveUnit: "admin/saveUnit",
			},
			form: {
				loadForms: "admin/getForms",
				saveForm: "admin/saveForm",
			},
			tax: {
				loadTaxes: "admin/getTaxes",
				saveTax: "admin/saveTax",
			},
			user: {
				loadUsers: "admin/getUsers",
				saveUser: "admin/saveUser",
				changePassword: "admin/changePassword",
				loadRoleTypes: "admin/gurts",
				saveSignature: "admin/ss",
			},
			role: {
				loadRoles: "admin/getRoles",
				saveRole: "admin/saveRole",
			},
			permission: {
				loadPermissions: "admin/getPermissions",
			},
			service: {
				getServices: "/admin/service/gss",
				saveService: "/admin/service/ss",
				getRoles: "/admin/service/guts",
			},
			customDefault: {
				deleteCustomDefault: "/admin/customDefault/dcd",
				getCategories: "/admin/customDefault/gcdcs",
				saveAssessment: "/admin/customDefault/sa",
				saveEvaluation: "/admin/customDefault/se",
				saveFocus: "/admin/customDefault/sf",
				saveIntervention: "/admin/customDefault/si",
			},
			icd: {
				getICDs: "/admin/icd/gicds",
				getLevels: "/admin/icd/gls",
				saveICD: "/admin/icd/sicd",
				saveLevel: "/admin/icd/sl",
			},
			laboratory: {
				getPCRComments: "/admin/laboratory/gpcrcs",
				getAntigenComments: "/admin/laboratory/gacs",
				savePCRComment: "/admin/laboratory/spcrc",
				saveAntigenComment: "/admin/laboratory/sac",
			},
			chartOfAccounts: {
				getChartOfAccounts: "/admin/coa/gcoas",
				getAssets: "/admin/coa/gas",
				getLiabilities: "/admin/coa/gls",
				getEquities: "/admin/coa/ges",
				getExpenses: "/admin/coa/geps",
				getRevenues: "/admin/coa/grs",
				saveAssets: "/admin/coa/sa",
				saveLiabilities: "/admin/coa/sl",
				saveEquities: "/admin/coa/ses",
				saveExpenses: "/admin/coa/seps",
				saveRevenues: "/admin/coa/srs",
			},
			generalLedger: {
				getActivities: "/admin/gl/gas",
				getAccountsReceivableActivities: "/admin/gl/garas",
				getAccountsPayableActivities: "/admin/gl/gapas",
				saveAccountsReceivableActivity: "/admin/gl/sara",
				deleteAccountsReceivableActivity: "/admin/gl/dara",
				saveAccountsPayableActivity: "/admin/gl/sapa",
				deleteAccountsPayableActivity: "/admin/gl/dapa",
			},
			workflowProperty: {
				getSurgicalAuthProperties: "/admin/wp/gsaps",
				getPharmacyProperties: "/admin/wp/gpps",
				saveWorkflowProperty: "/admin/wp/swp",
				eclaimUc: {
					getExportType: "/admin/wp/ecuc/gext",
					getFiles: "/admin/wp/ecuc/gfls",
					getMarriage: "/admin/wp/ecuc/gmre",
					getCareer: "/admin/wp/ecuc/gcrr",
					getNation: "/admin/wp/ecuc/gntn",
					getIdType: "/admin/wp/ecuc/gidt",
					getUuc: "/admin/wp/ecuc/guuc",
					getOperationType: "/admin/wp/ecuc/gopt",
					getChrgItem: "/admin/wp/ecuc/gchi",
					getUcae: "/admin/wp/ecuc/guae",
					getLabTest: "/admin/wp/ecuc/glbt",
					getAdpType: "/admin/wp/ecuc/gadpt",
					saveExportType: "/admin/wp/ecuc/sext",
					saveFiles: "/admin/wp/ecuc/sfls",
					saveMarriage: "/admin/wp/ecuc/smre",
					saveCareer: "/admin/wp/ecuc/scrr",
					saveNation: "/admin/wp/ecuc/sntn",
					saveIdType: "/admin/wp/ecuc/sidt",
					saveUuc: "/admin/wp/ecuc/suuc",
					saveOperationtype: "/admin/wp/ecuc/sopt",
					saveChrgItem: "/admin/wp/ecuc/schi",
					saveUcae: "/admin/wp/ecuc/suae",
					saveAdpType: "/admin/wp/ecuc/sadpt",
					saveLabTest: "/admin/wp/ecuc/slbt",
				},
			},
		},

		report: {
			inv: {
				getOrgs: "/report/inv/getOrgs",
				getInvs: "/report/inv/getInvs",
			},
			po: {
				getOrgs: "/report/po/getOrgs",
				getPOs: "/report/po/getPOs",
				getPO: "/report/po/getPO",
			},
			tf: {
				getOrgs: "/report/tf/getOrgs",
				getTFs: "/report/tf/getTFs",
			},
			hospitel: {
				getOrgs: "/report/hospitel/gos",
				getHospitels: "/report/hospitel/ghs",
			},
			lab: {
				getLabs: "/report/lab/gls",
			},
			cashier: {
				getAdmissions: "/report/cashier/getAdmissions",
				getRoles: "/report/cashier/getRoles",
				getPayeeOrders: "/report/cashier/gpos",
				getBillingToTodayByAdmission: "/report/cashier/gbttba",
			},
			finance: {
				getGeneralLedgers: "/report/finance/ggls",
				getGeneralLedgerTrans: "/report/finance/gglts",
				getOrgs: "/report/finance/gos",
				getSuppliers: "/report/finance/gss",
				getInsuranceCompanies: "/report/finance/gics",
				getAccountsPayableAgings: "/report/finance/gapas",
				getAccountsReceivableAgings: "/report/finance/garas",
			},
		},
		cashier: {
			searchPatient: "/hs/cashier/sp",
			createAdmission: "/hs/cashier/ca",
			discharge: "/hs/cashier/dis",
			deleteBillingStatement: "/hs/cashier/dbs",
			getOrgs: "/hs/cashier/gos",
			getHealthCares: "/hs/cashier/ghcs",
			getPayments: "/hs/cashier/gps",
			getBanks: "/hs/cashier/gbs",
			saveBilling: "/hs/cashier/sb",
			saveBillingPlans: "/hs/cashier/sbps",
			saveBillingPlanAdjustments: "/hs/cashier/sbpas",
			saveBillingStatementAdjustment: "/hs/cashier/sbsa",
			saveDoctorOrderPrescriptionOnly: "/hs/cashier/sdopo",
		},
		deposit: {
			searchPatient: "/his/deposit/sp",
			getPayments: "/his/deposit/gps",
			saveDeposit: "/his/deposit/sdp",
		},
		registration: {
			registerPatient: "/hs/registration/rp",
			getPatients: "/hs/registration/gps",
			getRegistrationSelectOption: "/hs/registration/grgopt",
			getIdType: "/hs/registration/gidt",
			getMarriage: "/hs/registration/gmre",
			getCareer: "/hs/registration/gcr",
			getNation: "/hs/registration/gnn",
			getHealthCares: "/hs/registration/ghcs",
			getClinicalRecordSelectOption: "/hs/registration/gcnopt",
			getExpenseEstimation: "/hs/registration/gee",
			getItems: "/hs/registration/gis",
			getTypes: "/hs/registration/gts",
			getServices: "/hs/registration/gss",
			getIsoPrecautions: "/hs/registration/gips",
			getFallRisks: "/hs/registration/gfrs",
			createClinicalRecord: "/hs/registration/ccr",
			uploadSatCodeFile: "/hs/registration/uscf",
			downloadFile: "/hs/registration/df",
			uploadNovelFile: "/hs/registration/un3",
			uploadIdFile: "/hs/registration/uid",
			uploadHealthCareFile: "/hs/registration/uhc",
			uploadCovidResultFile: "/hs/registration/ucr",
		},
		opd: {
			deleteDoctorOrder: "/his/opd/ddo",
			deleteGCSs: "/his/opd/dgcss",
			deleteGlucose: "/his/opd/ddtxs",
			deleteICD: "/his/opd/dicd",
			deleteIOs: "/his/opd/dios",
			deleteMedicalCertificate: "/his/opd/dmc",
			deleteMedicalRecord: "/his/opd/dmr",
			deleteNurseNote: "/his/opd/dnn",
			deleteNurseOrder: "/his/opd/dno",
			deleteOperatingRoomSchedules: "/his/opd/dorss",
			deletePainScales: "/his/opd/dpss",
			deletePupilSize: "/his/opd/dplss",
			deleteVitalSign: "/his/opd/dvs",
			getAnesthesias: "/his/opd/gas",
			getCustomDefaultCategories: "/his/opd/gcdcs",
			getFrontInventoriesByBranch: "/his/opd/gfisbb",
			getICDs: "/his/opd/gicds",
			getIntakes: "/his/opd/gits",
			getItems: "/his/opd/gis",
			getLevels: "/his/opd/gls",
			getOperatingRoomCases: "/his/opd/gorcs",
			getOperatingRoomSchedules: "/his/opd/gorss",
			getOperatingRooms: "/his/opd/gors",
			getOrgs: "/his/opd/gos",
			getOutputs: "/his/opd/gops",
			getPatients: "/his/opd/gps",
			getPatientsWithRegisteredOPD: "/his/opd/gpswropd",
			getServices: "/his/opd/gss",
			getTypes: "/his/opd/gts",
			getUnits: "/his/opd/gus",
			getUserTypes: "/his/opd/guts",
			saveCertificate: "/his/opd/smc",
			saveDoctorOrders: "/his/opd/sdos",
			saveGCSs: "/his/opd/sgcss",
			saveGlucose: "/his/opd/sdtx",
			saveICDs: "/his/opd/sicds",
			saveIOs: "/his/opd/sios",
			saveNurseNote: "/his/opd/snn",
			saveNurseOrders: "/his/opd/snos",
			saveNursePreOperativeCheckLists: "/his/opd/snpocls",
			saveOperatingRoomRequest: "/his/opd/sorr",
			savePSs: "/his/opd/spss",
			savePupilSize: "/his/opd/splss",
			saveVitalSign: "/his/opd/svs",
			scheduleAppointment: "/his/opd/sa",
			updateAppointmentDate: "/his/opd/uad",
			updateDoctorOrderRange: "/his/opd/udor",
			updateMedicalRecord: "/his/opd/umr",
			updateOperatingRoomRequest: "/his/opd/uorr",
		},
		ipd: {
			cancelDoctorOrder: "/hs/ipd/cdo",
			deleteDoctorOrder: "/hs/ipd/ddo",
			deleteGCSs: "/hs/ipd/dgcss",
			deleteGlucose: "/hs/ipd/ddtxs",
			deleteICD: "/hs/ipd/dicd",
			deleteIOs: "/hs/ipd/dios",
			deleteMedicalCertificate: "/hs/ipd/dmc",
			deleteNurseNote: "/hs/ipd/dnn",
			deleteNurseOrder: "/hs/ipd/deleteNurseOrder",
			deletePainScales: "/hs/ipd/dpss",
			deletePupilSizes: "/hs/ipd/dplss",
			deleteSOAP: "/hs/ipd/dsoap",
			deleteVitalSign: "/hs/ipd/dvs",
			dischargeHospitel: "/hs/ipd/dh",
			getCategories: "/hs/ipd/getCategories",
			getCustomDefaultCategories: "/hs/ipd/gcdcs",
			getHospitels: "/hs/ipd/ghs",
			getICDs: "/hs/ipd/gicds",
			getIntakes: "/hs/ipd/gits",
			getItems: "/hs/ipd/gis",
			getLevels: "/hs/ipd/gls",
			getOrgs: "/hs/ipd/gos",
			getOutputs: "/hs/ipd/gops",
			getPatient: "/hs/ipd/gp",
			getPatientsByFilter: "/hs/ipd/gpsbf",
			getServices: "/hs/ipd/gss",
			getTypes: "/hs/ipd/gts",
			getUnits: "/hs/ipd/gus",
			getUserTypes: "/hs/ipd/guts",
			registerHospitel: "/hs/ipd/rh",
			saveCertificate: "/hs/ipd/smc",
			saveGCSs: "/hs/ipd/sgcss",
			saveGlucose: "/hs/ipd/sdtx",
			saveICDs: "/hs/ipd/sicds",
			saveIOs: "/hs/ipd/sios",
			saveNurseNote: "/hs/ipd/snn",
			saveNurseOrders: "/hs/ipd/saveNurseOrders",
			saveOrders: "/hs/ipd/sos",
			savePRNDispensingRecord: "/hs/ipd/sprndr",
			savePainScales: "/hs/ipd/spss",
			savePupilSizes: "/hs/ipd/splss",
			saveVitalSign: "/hs/ipd/svs",
			stopOrder: "/hs/ipd/so",
		},
		lab: {
			cancelLab: "/hs/lab/cll",
			completeLab: "/hs/lab/cpl",
			createLab: "/hs/lab/cl",
			getOrgs: "/hs/lab/gos",
			getServices: "/hs/lab/gss",
			getPatients: "/hs/lab/gps",
			getPatientsByFilter: "/hs/lab/gpsbf",
			getPCRCommentCategories: "/hs/lab/gpcrccs",
			getAntigenCommentCategories: "/hs/lab/gacs",
			registerPatient: "/hs/lab/rp",
			updateLabResult: "/hs/lab/ulr",
			updateLabUrl: "/hs/lab/ulurl",
		},
		pharmacy: {
			getPatients: "/his/pharmacy/gps",
			getOrgs: "/his/pharmacy/gos",
			getInventory: "/his/pharmacy/gi",
			deletePharmacyOrder: "/his/pharmacy/dpo",
			savePharmacyOrder: "/his/pharmacy/spo",
			saveDispensedPharmacyOrder: "/his/pharmacy/sdpo",
			savePharmacyReturnOrder: "/his/pharmacy/spro",
		},
		frontInventory: {
			getOrgs: "/his/frontInventory/gos",
			getTypes: "/his/frontInventory/gts",
			getPharmacyInventories: "/his/frontInventory/gisbl",
			saveWaste: "/his/frontInventory/sw",
			saveSplit: "/his/frontInventory/ss",
			updateInventory: "/his/frontInventory/ui",
			getInventoryConversionUnits: "/his/frontInventory/gicus",
		},
		operatingRoom: {
			approveAnesthesiaRecord: "/his/operatingRoom/aar",
			cancelOperatingRoomSchedules: "/his/operatingRoom/caorss",
			confirmNursePreOperativeCheckList: "/his/operatingRoom/cnpopcl",
			confirmOperatingRoomSchedules: "/his/operatingRoom/corss",
			confirmSurgicalSafetyCheckList: "/his/operatingRoom/csscl",
			deleteAneDoctorOrders: "/his/operatingRoom/dados",
			deleteAneIntakeOutputs: "/his/operatingRoom/daios",
			deleteSurgicalSafetyCheckList: "/his/operatingRoom/dsscl",
			deleteVitalSigns: "/his/operatingRoom/dvss",
			getAnesthesias: "/his/operatingRoom/gas",
			getICDs: "/his/operatingRoom/gicds",
			getIntakes: "/his/operatingRoom/gits",
			getItems: "/his/operatingRoom/gis",
			getOperatingRequests: "/his/operatingRoom/grs",
			getOperatingRoomCases: "/his/operatingRoom/gorcs",
			getOperatingRoomSchedules: "/his/operatingRoom/gorss",
			getOperatingRooms: "/his/operatingRoom/gors",
			getOrgs: "/his/operatingRoom/gos",
			getOutputs: "/his/operatingRoom/gops",
			getSBUs: "/his/operatingRoom/gsbus",
			getServices: "/his/operatingRoom/gss",
			getTypes: "/his/operatingRoom/gts",
			getUsers: "/his/operatingRoom/gus",
			saveAnesthesiaDoctorOrders: "/his/operatingRoom/sados",
			saveAnesthesiaIOs: "/his/operatingRoom/sainos",
			saveAnesthesiaDoctorOrderDetails: "/his/operatingRoom/sadods",
			saveAnesthesiaIntraOperatives: "/his/operatingRoom/saios",
			saveAnesthesiaPostOperatives: "/his/operatingRoom/sapos",
			saveAnesthesiaPreOperatives: "/his/operatingRoom/sapops",
			savePreOperativeCheckLists: "/his/operatingRoom/spopcls",
			saveSurgicalSafetyCheckListSignIn: "/his/operatingRoom/sssclsi",
			saveSurgicalSafetyCheckListSignOut: "/his/operatingRoom/sssclso",
			saveSurgicalSafetyCheckListTimeOut: "/his/operatingRoom/sssclto",
			saveVitalSigns: "/his/operatingRoom/svss",
			updateOperatingRoomRequests: "/his/operatingRoom/uorrs",
			updateOperatingRoomSchedules: "/his/operatingRoom/uorss",
		},
		surgicalAuth: {
			approveOperatingRoomRequest: "/his/surgicalAuth/aorr",
			getHealthCarePlans: "/his/surgicalAuth/ghcps",
			getItems: "/his/surgicalAuth/gis",
			getOperatingRequests: "/his/surgicalAuth/gors",
			getOrgs: "/his/surgicalAuth/gos",
			getServices: "/his/surgicalAuth/gss",
			getTypes: "/his/surgicalAuth/gts",
			inspectOperatingRoomRequest: "/his/surgicalAuth/iorr",
			rejectOperatingRoomRequest: "/his/surgicalAuth/rorr",
			updateOperatingRoomRequestBudgets: "/his/surgicalAuth/uorrbs",
			updateOperatingRoomRequestCosts: "/his/surgicalAuth/uorrcs",
		},
		accountsReceive: {
			approveReceipt: "/acc/accountsReceive/ar",
			getBanks: "/acc/accountsReceive/gbs",
			getInsuranceCompanies: "/acc/accountsReceive/gics",
			getOrgs: "/acc/accountsReceive/gos",
			getPayments: "/acc/accountsReceive/gps",
			getReceipts: "/acc/accountsReceive/grs",
			inspectReceipt: "/acc/accountsReceive/ir",
			rejectReceipt: "/acc/accountsReceive/rr",
			updateBillingGL: "/acc/accountsReceive/ubgl",
			updateDebtGL: "/acc/accountsReceive/udgl",
			updateDebtPlanGL: "/acc/accountsReceive/udpgl",
		},
		accountsReceivable: {
			completeAccountsReceivable: "/acc/accountsReceivable/car",
			getOrgs: "/acc/accountsReceivable/gos",
			getInvoices: "/acc/accountsReceivable/gis",
			getHealthCarePlans: "/acc/accountsReceivable/ghcps",
			updateBillingPlans: "/acc/accountsReceivable/ubps",
			updateBillingStatements: "/acc/accountsReceivable/ubss",
			updateBillingStatementGL: "/acc/accountsReceivable/ubsgl",
			inspectInvoice: "/acc/accountsReceivable/ii",
			rejectInvoice: "/acc/accountsReceivable/ri",
		},
		accountsPayable: {
			completeInvoice: "/acc/accountsPayable/ci",
			getOrgs: "/acc/accountsPayable/gos",
			getReceives: "/acc/accountsPayable/grcs",
			inspectInvoice: "/acc/accountsPayable/ii",
			rejectInvoice: "/acc/accountsPayable/ri",
			updateItemGL: "/acc/accountsPayable/uigl",
		},
		paymentSlip: {
			approvePaymentPlan: "/acc/paymentSlip/app",
			completePaymentSlipRequest: "/acc/paymentSlip/cpsr",
			deleteInvoices: "/acc/paymentSlip/dis",
			deletePaymentPlans: "/acc/paymentSlip/dpps",
			downloadFile: "/acc/paymentSlip/df",
			getBanks: "/acc/paymentSlip/gbs",
			getInvoices: "/acc/paymentSlip/gis",
			getOrgs: "/acc/paymentSlip/gos",
			getPaymentSlipRequests: "/acc/paymentSlip/gpsrs",
			getPayments: "/acc/paymentSlip/gps",
			getSuppliers: "/acc/paymentSlip/gss",
			getWithholdingTaxes: "/acc/paymentSlip/gwhts",
			inspectPaymentPlan: "/acc/paymentSlip/ipp",
			rejectPaymentPlan: "/acc/paymentSlip/rpp",
			savePaymentPlan: "/acc/paymentSlip/spp",
			savePaymentSlipRequest: "/acc/paymentSlip/spsr",
			saveWithholdingTaxCertificate: "/acc/paymentSlip/swhtc",
			updateInvoiceGL: "/acc/paymentSlip/uigl",
			updateFinancialOperationGL: "/acc/paymentSlip/ufogl",
			updatePaymentPlanApprover: "/acc/paymentSlip/uppa",
			updatePaymentPlanCreator: "/acc/paymentSlip/uppc",
			updatePaymentPlanInspector: "/acc/paymentSlip/uppi",
			updatePaymentPlanReject: "/acc/paymentSlip/uppr",
		},
		contractBilling: {
			getOrgs: "/acc/contract/gos",
			getContractBillings: "/acc/contract/gcbs",
			getHealthCarePlans: "/acc/contract/ghcps",
			getInsuranceCompanies: "/acc/contract/gics",
			getInvoices: "/acc/contract/gis",
			completeContractBilling: "/acc/contract/ccb",
			saveContractBilling: "/acc/contract/scb",
			updateContractBillingInvoices: "/acc/contract/ucbis",
			deleteContractBillingInvoices: "/acc/contract/dcbis",
			updateContractBilling: "/acc/contract/ucb",
		},
		debt: {
			getOrgs: "/acc/debt/gos",
			getHealthCarePlans: "/acc/debt/ghcps",
			getInsuranceCompanies: "/acc/debt/gics",
			getPayments: "/acc/debt/gps",
			getBanks: "/acc/debt/gbs",
			getInvoices: "/acc/debt/gis",
			getDebtPlans: "/acc/debt/gdps",
			completeDebtPlans: "/acc/debt/cdps",
			saveDebtPlans: "/acc/debt/sdps",
			saveAccountantAdjustment: "/acc/debt/saa",
			updateDebtPlanPayment: "/acc/debt/udpp",
			deleteDebtPlanInvoices: "/acc/debt/ddpis",
		},
		financialOperations: {
			completeFinancialRequest: "/acc/financialOperations/cfr",
			completeFinancialTransation: "/acc/financialOperations/cft",
			deletePaymentPlans: "/acc/financialOperations/dpps",
			downloadFile: "/acc/financialOperations/df",
			getBanks: "/acc/financialOperations/gbs",
			getFinancialRequests: "/acc/financialOperations/gfrs",
			getOrgs: "/acc/financialOperations/gos",
			getPaymentPlans: "/acc/financialOperations/gpps",
			getPayments: "/acc/financialOperations/gps",
			getServiceTypes: "/acc/financialOperations/gsts",
			saveFinancialRequest: "/acc/financialOperations/sfr",
			updateFinancialRequest: "/acc/financialOperations/ufr",
			uploadFile: "/acc/financialOperations/uf",
		},
		postingGL: {
			completePostingGL: "/acc/postingGL/cpgl",
			deleteEntry: "/acc/postingGL/de",
			getGLDocuments: "/acc/postingGL/gglds",
			getOrgs: "/acc/postingGL/gos",
			getPostingGLs: "/acc/postingGL/gpgls",
			inspectPostingGL: "/acc/postingGL/ipgl",
			rejectPostingGL: "/acc/postingGL/rpgl",
			savePostingGL: "/acc/postingGL/spgl",
			updateEntry: "/acc/postingGL/ue",
		},
		purchaseInvoice: {
			completePurchaseInvoice: "/acc/purchaseInvoice/cpi",
			deletePurchases: "/acc/purchaseInvoice/dps",
			getOrgs: "/acc/purchaseInvoice/gos",
			getPayments: "/acc/purchaseInvoice/gps",
			getPurchaseInvoices: "/acc/purchaseInvoice/gpis",
			getSuppliers: "/acc/purchaseInvoice/gss",
			getTaxes: "/acc/purchaseInvoice/gts",
			getWithholdingTaxes: "/acc/purchaseInvoice/gwhts",
			inspectPurchaseInvoice: "/acc/purchaseInvoice/ipi",
			rejectPurchaseInvoice: "/acc/purchaseInvoice/rpi",
			savePurchaseInvoices: "/acc/purchaseInvoice/spis",
			updatePurchaseLink: "/acc/purchaseInvoice/upl",
			updatePurchases: "/acc/purchaseInvoice/ups",
		},
		paymentVoucher: {
			completePaymentVoucher: "/acc/paymentVoucher/cpv",
			deletePurchases: "/acc/paymentVoucher/dps",
			getOrgs: "/acc/paymentVoucher/gos",
			getPaymentVouchers: "/acc/paymentVoucher/gpvs",
			getPayments: "/acc/paymentVoucher/gps",
			getSuppliers: "/acc/paymentVoucher/gss",
			getTaxes: "/acc/paymentVoucher/gts",
			getWithholdingTaxes: "/acc/paymentVoucher/gwhts",
			savePaymentVouchers: "/acc/paymentVoucher/spvs",
			updatePurchases: "/acc/paymentVoucher/ups",
		},
		notification: {
			getPatients: "/menu/notification/gps",
			saveNotifications: "/menu/notification/sns",
			deleteNotifications: "/menu/notification/dns",
		},
		timeline: {
			getPatients: "/menu/timeline/gps",
		},
		document: {
			deleteUploadDocument: "/menu/document/dud",
			downloadUploadDocument: "/menu/document/dlud",
			getPatient: "/menu/document/gp",
			getUploadDocuments: "/menu/document/guds",
			uploadDocument: "/menu/document/sud",
		},
	},
}

export default Url
