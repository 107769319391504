import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPSInvoices, setPSRequests, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_PS_SELECTED_INVOICE, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipRightTabInvoice extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.PS.selectedSupplier.supplier) {
			this.props.setPSInvoices(this.props.PS.selectedSupplier.supplier.invoices)
		} else {
			this.props.setPSInvoices([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.deleteInvoice)) {
				this.deleteInvoices()
			}
		}

		if (
			!_.isEqual(prevProps.PS.selectedSupplier, this.props.PS.selectedSupplier) ||
			!_.isEqual(prevProps.PS.selectedPaymentPlan, this.props.PS.selectedPaymentPlan) ||
			!_.isEqual(prevProps.PS.selectedCompletePaymentPlan, this.props.PS.selectedCompletePaymentPlan) ||
			!_.isEqual(prevProps.PS.isPaymentPlanCompleteTab, this.props.PS.isPaymentPlanCompleteTab)
		) {
			if (this.props.PS.selectedSupplier.supplier) {
				this.props.setPSInvoices(this.props.PS.selectedSupplier.supplier.invoices)
			} else {
				this.props.setPSInvoices([])
			}
		}

		if (
			!_.isEqual(prevProps.PS.selectedPaymentPlan, this.props.PS.selectedPaymentPlan) ||
			!_.isEqual(prevProps.PS.selectedCompletePaymentPlan, this.props.PS.selectedCompletePaymentPlan) ||
			!_.isEqual(prevProps.PS.isPaymentPlanCompleteTab, this.props.PS.isPaymentPlanCompleteTab)
		) {
			this.props.setSelected(SET_PS_SELECTED_INVOICE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_SELECTED_INVOICE, -1, null)
	}

	deleteInvoices() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.deleteInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PS.location.id,
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				userId: this.props.login.user.id,
				requests: [
					{
						paymentSlips: [
							{
								id: this.props.PS.selectedSupplier.supplier.id,
								invoices: [
									{
										id: this.props.PS.selectedInvoice.invoice.id,
										receiveInvoice: this.props.PS.selectedInvoice.invoice.receiveInvoice,
										purchaseInvoice: this.props.PS.selectedInvoice.invoice.purchaseInvoice,
										paymentVoucher: this.props.PS.selectedInvoice.invoice.paymentVoucher,
									},
								],
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== this.props.PS.selectedPaymentSlip.ps.id)
			if (res.data.requests.length > 0) {
				requests.push(res.data.requests[0])
			}
			this.props.setPSRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_PS_SELECTED_INVOICE, index, this.props.PS.invoiceTable.filtered[index])
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedInvoice = this.props.PS.invoiceTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.paymentSlip.deleteInvoice)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					{(selectedInvoice.receiveInvoice || selectedInvoice.purchaseInvoice) && (
						<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceDate)} span={1}>
							{Utils.formatDate(selectedInvoice.receiveInvoice ? selectedInvoice.receivedDateTime : selectedInvoice.invoiceDateTime)}
						</Descriptions.Item>
					)}
					{(selectedInvoice.receiveInvoice || selectedInvoice.purchaseInvoice) && (
						<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceId)} span={1}>
							{selectedInvoice.invoice}
						</Descriptions.Item>
					)}
					{selectedInvoice.receiveInvoice && (
						<Descriptions.Item label={translate(ResourceAssistance.Message.accountsPayableRC)} span={1}>
							{selectedInvoice.receive.id}
						</Descriptions.Item>
					)}
					{selectedInvoice.purchaseInvoice && (
						<Descriptions.Item label={translate(ResourceAssistance.Message.accountsPayablePI)} span={1}>
							{selectedInvoice.id}
						</Descriptions.Item>
					)}
					{selectedInvoice.paymentVoucher && (
						<Descriptions.Item label={translate(ResourceAssistance.Message.accountsPayablePV)} span={1}>
							{selectedInvoice.id}
						</Descriptions.Item>
					)}
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedInvoice.payment}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedInvoice.taxedTotalPrice).toFixed(2))}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let total = this.props.PS.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })]}
						btnIcons={[<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onDelete]}
						data={this.props.PS.invoiceTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.selectedInvoice.index === -1}
						highlightedRow={this.props.PS.selectedInvoice.index}
						extra={
							!_.isEmpty(this.props.PS.invoiceTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)}>
												{Utils.formatNumWithComma(total.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPSInvoices,
			setPSRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipRightTabInvoice))
