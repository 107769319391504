import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setPSGLGroups } from "~/redux/action"
import { SET_PS_GENERAL_LEDGER_DISPLAY, SET_PS_SELECTED_GROUP } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipRightTabGL extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onCOA = this.onCOA.bind(this)
	}

	componentDidMount() {
		if (this.props.PS.selectedSupplier.supplier) {
			this.props.setPSGLGroups(this.props.PS.selectedSupplier.supplier.invoices)
		} else {
			this.props.setPSGLGroups([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.PS.selectedPaymentPlan, this.props.PS.selectedPaymentPlan) ||
			!_.isEqual(prevProps.PS.selectedCompletePaymentPlan, this.props.PS.selectedCompletePaymentPlan) ||
			!_.isEqual(prevProps.PS.selectedSupplier, this.props.PS.selectedSupplier)
		) {
			if (this.props.PS.selectedSupplier.supplier) {
				this.props.setPSGLGroups(this.props.PS.selectedSupplier.supplier.invoices)
			} else {
				this.props.setPSGLGroups([])
			}
		}
		if (
			!_.isEqual(prevProps.PS.selectedPaymentPlan, this.props.PS.selectedPaymentPlan) ||
			!_.isEqual(prevProps.PS.selectedCompletePaymentPlan, this.props.PS.selectedCompletePaymentPlan) ||
			!_.isEqual(prevProps.PS.isPaymentPlanCompleteTab, this.props.PS.isPaymentPlanCompleteTab)
		) {
			this.props.setSelected(SET_PS_SELECTED_GROUP, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_SELECTED_GROUP, -1, null)
	}

	isDisplayEdit() {
		return (
			(this.props.PS.selectedPaymentPlan.paymentPlan && !this.props.PS.selectedPaymentPlan.paymentPlan.creator && !this.props.PS.isPaymentPlanCompleteTab) ||
			(this.props.PS.selectedCompletePaymentPlan.paymentPlan &&
				!this.props.PS.selectedCompletePaymentPlan.paymentPlan.financialOperationInspected &&
				this.props.PS.isPaymentPlanCompleteTab)
		)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_PS_SELECTED_GROUP, index, this.props.PS.generalLedgerGroupTable.filtered[index])
	}

	onCOA() {
		this.props.onModalDisplayAction(SET_PS_GENERAL_LEDGER_DISPLAY, true)
	}

	render() {
		let totalDebit = this.props.PS.generalLedgerGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		let totalCredit = this.props.PS.generalLedgerGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[5]))
		}, Utils.BigNumber(0))
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined]}
						onCheckedArray={[this.onCOA]}
						data={this.props.PS.generalLedgerGroupTable}
						isClearHighlight={this.props.PS.selectedGroup.index === -1}
						highlightedRow={this.props.PS.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.PS.generalLedgerGroupTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
												color: totalCredit.eq(totalDebit) ? undefined : ResourceAssistance.CSS.Color.red,
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.debit)}>
												{Utils.formatNumWithComma(totalDebit.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.credit)}>
												{Utils.formatNumWithComma(totalCredit.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setPSGLGroups,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipRightTabGL))
