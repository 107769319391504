import { Card, Space } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { BiCheckSquare } from "react-icons/bi"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPharmacyPatients,
	setPharmacyVerifiedOrders,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PHARMACY_SELECTED_VERIFIED_ORDER, SET_WARNING_DISPLAY } from "~/redux/type"
import { SET_PAGE_LOADING } from "~/redux/type/"
import PharmacyPrintableDataFactory from "~/utils/factory/print/HIS/PharmacyPrintableDataFactory"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightVerifiedOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightVerifiedOrder",
		}

		this.onSelectVerified = this.onSelectVerified.bind(this)
		this.onDispense = this.onDispense.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onLabel = this.onLabel.bind(this)
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
			this.props.setPharmacyVerifiedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
		} else {
			this.props.setPharmacyVerifiedOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.pharmacy.deleteVerifiedOrder)) {
					this.deletePharmacyOrder()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.pharmacy.dispenseVerifiedOrder)) {
					this.dispensePharmacyOrder()
				}
			}
		}
		if (!_.isEqual(prevProps.pharmacy.selectedDoctorOrder, this.props.pharmacy.selectedDoctorOrder)) {
			if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
				this.props.setPharmacyVerifiedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
			} else {
				this.props.setPharmacyVerifiedOrders([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.verifiedOrderTable.filtered, this.props.pharmacy.verifiedOrderTable.filtered)) {
			let index = this.props.pharmacy.selectedVerifiedOrder.verifiedOrder
				? this.props.pharmacy.verifiedOrderTable.filtered.findIndex(
						(verifiedOrder) => verifiedOrder.id === this.props.pharmacy.selectedVerifiedOrder.verifiedOrder.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(SET_PHARMACY_SELECTED_VERIFIED_ORDER, index, this.props.pharmacy.verifiedOrderTable.filtered[index])
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_VERIFIED_ORDER, -1, null)
			}
		}
	}

	dispensePharmacyOrder() {
		let data = this.props.pharmacy.selectedPatient.patient.isSearchPatient
			? {
					pid: this.props.pharmacy.selectedPatient.patient.id,
			  }
			: {
					startDateTime: this.props.pharmacy.startDateTime,
					endDateTime: this.props.pharmacy.endDateTime,
			  }
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.pharmacy.saveDispensedPharmacyOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign(data, {
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				userId: this.props.login.user.id,
				pharmacyOrders: [
					{
						id: this.props.pharmacy.selectedVerifiedOrder.verifiedOrder.id,
					},
				],
			}),
		}
		let callback = (res) => {
			if (this.props.pharmacy.selectedPatient.patient.isSearchPatient) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						isSearchPatient: true,
					})
				)
			}
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deletePharmacyOrder() {
		let data = this.props.pharmacy.selectedPatient.patient.isSearchPatient
			? {
					pid: this.props.pharmacy.selectedPatient.patient.id,
			  }
			: {
					startDateTime: this.props.pharmacy.startDateTime,
					endDateTime: this.props.pharmacy.endDateTime,
			  }
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.pharmacy.deletePharmacyOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign(data, {
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				pharmacyOrders: [
					{
						id: this.props.pharmacy.selectedVerifiedOrder.verifiedOrder.id,
					},
				],
			}),
		}
		let callback = (res) => {
			if (this.props.pharmacy.selectedPatient.patient.isSearchPatient) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						isSearchPatient: true,
					})
				)
			}
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectVerified(order, index) {
		this.props.setSelected(SET_PHARMACY_SELECTED_VERIFIED_ORDER, index, this.props.pharmacy.verifiedOrderTable.filtered[index])
	}

	onLabel(event, row, rIdx, cIdx) {
		let selectedPharmacyOrder = this.props.pharmacy.verifiedOrderTable.filtered[rIdx]
		let data = PharmacyPrintableDataFactory.generateOrderLabelData(
			this.props.pharmacy.selectedPatient.patient,
			this.props.pharmacy.selectedDoctorOrder.doctorOrder,
			selectedPharmacyOrder,
			this.props.pharmacy.pharmacyWP.displayTitle ? Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs) : undefined
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.pharmacy.orderLabel, "_blank")
		myWindow.data = data
	}

	onDispense(event, row, rIdx, cIdx) {
		let selectedOrder = this.props.isDoctorOrder
			? this.props.pharmacy.doctorOrderTable.filtered[this.props.pharmacy.selectedDoctorOrder.index]
			: this.props.pharmacy.nurseOrderTable.filtered[this.props.pharmacy.selectedDoctorOrder.index]
		let selectedPharmacyOrder = this.props.pharmacy.verifiedOrderTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.pharmacy.dispenseVerifiedOrder)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.lg)
		this.props.setWarningMsgAction(
			<Fragment>
				<span style={{ fontSize: "28px" }}>{translate(ResourceAssistance.Message.dispense)}</span>
				<fieldset>
					<legend>
						{this.props.isDoctorOrder ? translate(ResourceAssistance.Hospitel.doctorStandingOrder) : translate(ResourceAssistance.Message.nurseOrder)}
					</legend>
					<Space direction="vertical" size={"middle"} align={"center"}>
						<div>{selectedOrder.code}</div>
						<div>{selectedOrder.description}</div>
						<div>{selectedOrder.healthcarePlan}</div>
						<div>{selectedOrder.notes}</div>
						<div>{selectedOrder.lastModifiedBy.displayName}</div>
					</Space>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.pharmacistVerified)}</legend>
					<Space direction="vertical" size={"middle"} align={"center"}>
						<div>
							{Utils.formatDate(selectedPharmacyOrder.startDateTime)} - {Utils.formatDate(selectedPharmacyOrder.endDateTime)}
						</div>
						<div>
							{translate(ResourceAssistance.Message.amount)}: {Utils.formatNumWithComma(selectedPharmacyOrder.amount)} {selectedPharmacyOrder.unit}
						</div>
						<div>{this.props.login.user.displayName}</div>
					</Space>
				</fieldset>
			</Fragment>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedVerifiedOrder = this.props.pharmacy.verifiedOrderTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.pharmacy.deleteVerifiedOrder)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.lg)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.deleteVerifiedPharmacyOrder)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.code}</p>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.description}</p>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.notes}</p>
				<p>
					{Utils.formatDate(selectedVerifiedOrder.startDateTime)} - {Utils.formatDate(selectedVerifiedOrder.endDateTime)}
				</p>
				<p>
					{translate(ResourceAssistance.Message.amount)}: {Utils.formatNumWithComma(selectedVerifiedOrder.amount)} {selectedVerifiedOrder.unit}
				</p>
				<p>{selectedVerifiedOrder.lastModifiedBy.displayName}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlineInfo,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<IconFont type={"icon-ali-pmgcare-zhishiku-yaopinshuomingshu"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
							<BiCheckSquare size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onLabel, this.onDispense, this.onDelete]}
						data={this.props.pharmacy.verifiedOrderTable}
						onClick={this.onSelectVerified}
						isClearHighlight={this.props.pharmacy.selectedVerifiedOrder.index === -1}
						highlightedRow={this.props.pharmacy.selectedVerifiedOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	common: state.common,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPharmacyPatients,
			setPharmacyVerifiedOrders,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightVerifiedOrder))
