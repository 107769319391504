import { Descriptions } from "antd"
import React from "react"
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { ImAttachment } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class IPDRightInfoRow extends React.Component {
	constructor(props) {
		super(props)

		this.onUploadId = this.onUploadId.bind(this)
	}

	onUploadId(event) {
		let idFileBlob = event.target.files[0]
		if (!idFileBlob || !idFileBlob.name.toLowerCase().endsWith(".pdf")) {
			return
		}
		this.props.uploadPatientFile(ResourceAssistance.Url.registration.uploadIdFile, idFileBlob)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset className={ResourceAssistance.CSS.fullFlex}>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "1", flexDirection: "column" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={4}
							extra={
								Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) && (
									<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.FormControl.size.sm}>
										<DropdownToggle variant={ResourceAssistance.Button.variant.primary} disabled={this.props.ipd.selectedPatient.index === -1}>
											<ImAttachment size={ResourceAssistance.ReactIcon.size} />
											{translate(ResourceAssistance.Message.attachment)}
										</DropdownToggle>
										<DropdownMenu variant={"info"}>
											<label htmlFor="id" className={`dropdown-item ${this.props.ipd.selectedPatient.index === -1 ? "disabled" : ""}`}>
												{translate(ResourceAssistance.Message.idCard)}
											</label>
											<DropdownItem
												id={"id"}
												as={"input"}
												type={ResourceAssistance.FormControl.type.file}
												style={{ display: "none" }}
												disabled={this.props.ipd.selectedPatient.index === -1}
												accept={"application/pdf"}
												onChange={this.onUploadId}
											/>
										</DropdownMenu>
									</Dropdown>
								)
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={2}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.identificationNumber : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.idType)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.idType : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.nameTitle)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.title : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.firstName)} span={2}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.firstName : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.lastName)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.lastName : " "}
							</Descriptions.Item>
							<Descriptions.Item span={1} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.firstNameEng)} span={2}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.firstNameEng : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.lastNameEng)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.lastNameEng : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.gender)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.gender : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{this.props.ipd.selectedPatient.patient ? Utils.formatDate(this.props.ipd.selectedPatient.patient.dobtime) : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)} span={2}>
								{this.props.ipd.selectedPatient.patient
									? `${Utils.calculateAge(this.props.ipd.selectedPatient.patient.dobtime)} ${this.props.intl.formatMessage({
											id: ResourceAssistance.Hospitel.year,
									  })} ${Utils.calculateAgeMonth(this.props.ipd.selectedPatient.patient.dobtime)} ${this.props.intl.formatMessage({
											id: ResourceAssistance.Hospitel.month,
									  })}`
									: " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.maritalStatus)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.marriage : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.nation)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.nation : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.race)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.race : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.career)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.career : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.tel : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.telSecondary : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={2}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.telTertiary : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.address)} span={4}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.address : " "}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightInfoRow))
