import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"

class ReportTitle extends React.Component {
	render() {
		return (
			<Fragment>
				{/* doc number */}
				{this.props.data.documentNo && (
					<View fixed>
						<Text style={styles.documentNo}>No. {this.props.data.documentNo}</Text>
					</View>
				)}

				{/* Org Info */}
				<View style={[ResourceAssistance.Report.styles.row]}>
					{/* Info */}
					<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "center", marginRight: 10 }]}>
						{this.props.displayOrg && (
							<View style={[ResourceAssistance.Report.styles.row, this.props.styles.org]}>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.props.data.name} </Text>
								</View>
							</View>
						)}
						{this.props.displayBranch && (
							<View style={[ResourceAssistance.Report.styles.row, this.props.styles.branch]}>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.props.data.branchName} </Text>
								</View>
							</View>
						)}
						{this.props.displayAddress && (
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text style={ResourceAssistance.Report.styles.textItalic}>{this.props.data.address} </Text>
								</View>
							</View>
						)}
						{this.props.displayTel && (
							<View style={ResourceAssistance.Report.styles.row}>
								<Fragment>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{ResourceAssistance.Report.intl.tel}: </Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{this.props.data.tel} </Text>
									</View>
								</Fragment>
							</View>
						)}
						{this.props.displayFax && (
							<View style={ResourceAssistance.Report.styles.row}>
								<Fragment>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{ResourceAssistance.Report.intl.fax}: </Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{this.props.data.fax} </Text>
									</View>
								</Fragment>
							</View>
						)}
						{this.props.displayTaxNum && (
							<View style={ResourceAssistance.Report.styles.row}>
								<Fragment>
									<View style={ResourceAssistance.Report.styles.colAuto}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>
											{ResourceAssistance.Report.intl.taxId}/{this.props.data.taxIdIntl}:{" "}
										</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text style={ResourceAssistance.Report.styles.textItalic}>{this.props.data.taxId} </Text>
									</View>
								</Fragment>
							</View>
						)}
					</View>
					{/* Logo */}
					{this.props.data.logo && this.props.displayLogo && (
						<View style={[ResourceAssistance.Report.styles.colAuto, { justifyContent: "flex-start" }]}>
							{this.props.data.logo ? (
								<Image style={[styles.orgImg, this.props.styles.orgImg]} src={this.props.data.logo} alt="Logo" />
							) : (
								<Image style={styles.noImg} src={"data:image/jpeg,base64"} alt="Logo" />
							)}
						</View>
					)}
				</View>

				{/* Title */}
				{this.props.displayTitle && (
					<View style={[ResourceAssistance.Report.styles.row, ResourceAssistance.Report.styles.title, this.props.styles.title]}>
						<View style={ResourceAssistance.Report.styles.col}>
							{this.props.data.titleIntl && (
								<View
									style={[
										ResourceAssistance.Report.styles.row,
										{
											lineHeight: this.props.displayTitleEng && this.props.data.title && !_.isEqual(this.props.data.titleIntl, this.props.data.title) ? 0.8 : 1,
										},
									]}
								>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.props.data.titleIntl}</Text>
									</View>
								</View>
							)}
							{this.props.displayTitleEng && !_.isEqual(this.props.data.titleIntl, this.props.data.title) && (
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.props.data.title}</Text>
									</View>
								</View>
							)}
						</View>
					</View>
				)}
				{this.props.displayTitleSeparator && <View style={ResourceAssistance.Report.styles.separator} />}
			</Fragment>
		)
	}
}

const styles = StyleSheet.create({
	orgImg: {
		width: 200,
		objectFit: "contain",
	},
	noImg: {
		width: 200,
		height: 100,
		objectFit: "contain",
	},
	documentNo: {
		position: "absolute",
		top: "0px",
		left: "0px",
		width: "100px",
		zIndex: 100,
	},
})

ReportTitle.propTypes = {
	data: PropTypes.any.isRequired,
	displayTitle: PropTypes.bool,
	displayOrg: PropTypes.bool,
	displayBranch: PropTypes.bool,
	displayTaxNum: PropTypes.bool,
	displayFax: PropTypes.bool,
	displayTel: PropTypes.bool,
	displayLogo: PropTypes.bool,
	displayAddress: PropTypes.bool,
	displayTitleEng: PropTypes.bool,
	displayTitleSeparator: PropTypes.bool,
	styles: PropTypes.object,
}

ReportTitle.defaultProps = {
	displayTitle: true,
	displayOrg: true,
	displayBranch: false,
	displayTaxNum: true,
	displayFax: true,
	displayTel: true,
	displayLogo: true,
	displayAddress: true,
	displayTitleEng: true,
	displayTitleSeparator: true,
	styles: {},
}

export default ReportTitle
