import * as fontkit from "fontkit"
import _ from "lodash"
import { PDFDocument, rgb } from "pdf-lib"
import { createIntl, createIntlCache } from "react-intl"
import sarabun from "~/fonts/TH-Sarabun-New-Regular.ttf"
import { LOCALES, ResourceAssistance } from "~/i18n"
import Messages from "~/i18n/message"
import { store } from "~/redux/Store"
import ServerUtils from "~/utils/ServerUtils"
import { Utils } from "~/utils/Utils"

class PrintableDataFactory {
	intl = undefined

	static getIntl = () => {
		if (this.intl === undefined || !_.isEqual(this.locale, store.getState().language.locale)) {
			this.locale = store.getState().language.locale
			this.messages = Messages[this.locale]
			this.cache = createIntlCache()
			this.intl = createIntl({ locale: this.locale, messages: this.messages }, this.cache)
		}
		return this.intl
	}

	static generateClinicalReportData = (patient, logo) => {
		let sortedVitalSign = patient.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(a.dataTime, b.dateTime))
		let vs = sortedVitalSign.length > 0 ? sortedVitalSign[0] : {}
		let data = {
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			dobIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			ageIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.age }),
			healthCarePlanIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.healthCarePlan }),
			telIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.tel }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			allergiesIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.allergies }),
			underlyingDiseaseIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.underlyingDisease }),
			pastIllnessesIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.pastIllnesses }),
			diagnosisIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.diagnosis }),
			identificationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			treatmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.treatment }),
			icdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.internationalClassificationDiseases }),
			genderIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.gender }),
			nationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.nation }),
			raceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.race }),
			maritalStatusIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.maritalStatus }),
			emergencyContactIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.emergencyContact }),

			logo: logo,
			name: process.env.REACT_APP_ORG_NAME,
			branchName: process.env.REACT_APP_BRANCH_NAME,
			address: process.env.REACT_APP_ADDRESS,
			tel: process.env.REACT_APP_TEL,
			taxId: process.env.REACT_APP_TAX_ID,

			date: Utils.formatDateTime(patient.clinicalRecord.checkInDateTime),

			pid: patient.id,
			hn: patient.clinicalRecord.hn,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			dob: Utils.formatDate(patient.dobtime),
			age: Utils.calculateAge(patient.dobtime),
			identification: patient.identificationNumber,
			gender: patient.gender,
			nation: patient.nation,
			race: patient.race,
			maritalStatus: patient.marriage,
			healthCarePlan: patient.clinicalRecord.healthcareName,
			patientTel: patient.tel,
			patientAddress: patient.address,
			emergencyContactName: patient.relativeFirstName + " " + patient.relativeLastName,
			emergencyContactTel: patient.relativeTel,

			allergies: patient.allergies,
			underlyingDisease: patient.underlyingDisease,
			pastIllnesses: patient.pastIllnesses,

			temperature: vs.temperature,
			pr: vs.pr,
			rr: vs.rr,
			bp: vs.bp,
			oxygenSat: vs.oxygenSat,
			bw: patient.weight,
			ht: patient.height,
			bmi: Utils.BigNumber(patient.weight)
				.dividedBy(patient.height / 100)
				.dividedBy(patient.height / 100)
				.toFixed(2),

			cc: patient.clinicalRecord.cc,
			pi: patient.clinicalRecord.pi,
			ga: patient.clinicalRecord.ga,
			heentChecked: patient.clinicalRecord.heent === ResourceAssistance.CONSTANT.NORMAL,
			heentNote: patient.clinicalRecord.heentNote,
			heartChecked: patient.clinicalRecord.heart === ResourceAssistance.CONSTANT.NORMAL,
			heartNote: patient.clinicalRecord.heartNote,
			lungChecked: patient.clinicalRecord.lung === ResourceAssistance.CONSTANT.NORMAL,
			lungNote: patient.clinicalRecord.lungNote,
			abdomenChecked: patient.clinicalRecord.abdomen === ResourceAssistance.CONSTANT.NORMAL,
			abdomenNote: patient.clinicalRecord.abdomenNote,
			backChecked: patient.clinicalRecord.back === ResourceAssistance.CONSTANT.NORMAL,
			backNote: patient.clinicalRecord.backNote,
			extremitiesChecked: patient.clinicalRecord.extremities === ResourceAssistance.CONSTANT.NORMAL,
			extremitiesNote: patient.clinicalRecord.extremitiesNote,
			neuroChecked: patient.clinicalRecord.neuro === ResourceAssistance.CONSTANT.NORMAL,
			neuroNote: patient.clinicalRecord.neuroNote,
			others: patient.clinicalRecord.physicalExaminationOthers,

			diagnosis: patient.clinicalRecord.diagnosis,

			icds: patient.clinicalRecord.icds,

			signatureDate: Utils.formatDateTime(new Date().getTime()),
		}
		return data
	}

	static generateBillReportDate = (patient, admission, cashier, services, logo) => {
		let subTotal = Utils.BigNumber(0)
		let serverAdjustments = Utils.BigNumber(0)
		let billingPlanAdjustments = admission.billingPlans.reduce((totalAdjustments, cur) => {
			return totalAdjustments.plus(ServerUtils.calculateBillingPlanAdjustments(cur.billingPlanAdjustments))
		}, Utils.BigNumber(0))
		let grandTotal = Utils.BigNumber(0)

		return {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			adjustmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discount }),
			admissionDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dischargeDate }),
			subTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.subTotal }),
			admissionIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.admission }),
			adjustmentsIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.adjustments }),

			logo: logo,
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : process.env.REACT_APP_ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : process.env.REACT_APP_BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : process.env.REACT_APP_ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : process.env.REACT_APP_TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : process.env.REACT_APP_TAX_ID,

			pid: patient.id,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientAddress: patient.address,
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			services: services.map((service) => {
				subTotal = subTotal.plus(service.details.amount)
				serverAdjustments = serverAdjustments.plus(service.details.adjustment)
				grandTotal = grandTotal.plus(service.details.amount).minus(service.details.adjustment)
				return {
					description: Utils.trim(service.description),
					amount: Utils.formatNumWithComma(Utils.BigNumber(service.details.amount).toFixed(2)),
					adjustments: Utils.formatNumWithComma(Utils.BigNumber(service.details.adjustment).negated().toFixed(2)),
				}
			}),
			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			grandAdjustment: Utils.formatNumWithComma(serverAdjustments.plus(billingPlanAdjustments).negated().toFixed(2)),
			grandTotal: Utils.formatNumWithComma(grandTotal.minus(billingPlanAdjustments).toFixed(2)),

			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }) + "/Patient",
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier",
			],
			signatureNames: [patient.firstName + " " + patient.lastName, cashier.firstName + " " + cashier.lastName],
		}
	}

	static generateHealthCarePlanReceiptData(patient, admission, billingPlan, billingStatements, billing, user, logo) {
		let subTotal = Utils.BigNumber(0)
		let serviceAdjustments = Utils.BigNumber(0)
		let billingPlanAdjustments = ServerUtils.calculateBillingPlanAdjustments(billingPlan.billingPlanAdjustments)
		let refund = Utils.BigNumber(0)

		let data = {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.subTotal }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			invoiceIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceId }),
			receiptIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.receiptId }),
			adjustmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discount }),
			balanceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dischargeDate }),
			admissionIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.admission }),
			unpaidIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.unpaid }),
			changeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.change }),
			refundIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.refund }),
			collectFromIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.collectFrom }),

			logo: logo,
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : process.env.REACT_APP_ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : process.env.REACT_APP_BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : process.env.REACT_APP_ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : process.env.REACT_APP_TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : process.env.REACT_APP_TAX_ID,

			isReceipt: Utils.BigNumber(billing.total).gte(0) && !billing.debt,
			hasRefund: billingStatements.some((bs) => bs.refund),

			pid: patient.id,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientAddress: patient.address,
			receiptId: billing.id,
			printDate: Utils.formatDateTime(new Date()),
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			services: Utils.groupBy(
				billingStatements.map((service) => {
					subTotal = subTotal.plus(service.charge)
					serviceAdjustments = serviceAdjustments.plus(service.adjustment)
					if (service.refund) {
						refund = refund.plus(service.refund.amount)
					}
					return {
						description: service.name,
						categoryName: service.categoryName,
						amount: Utils.formatNumWithComma(Utils.BigNumber(service.charge).toFixed(2)),
						adjustment: Utils.formatNumWithComma(Utils.BigNumber(service.adjustment).negated().toFixed(2)),
						balance: Utils.formatNumWithComma(Utils.BigNumber(service.charge).minus(service.adjustment).toFixed(2)),
					}
				}),
				"categoryName"
			),

			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			adjustments: Utils.formatNumWithComma(serviceAdjustments.plus(billingPlanAdjustments).toFixed(2)),
			grandTotal: Utils.formatNumWithComma(subTotal.minus(serviceAdjustments).minus(billingPlanAdjustments).toFixed(2)),
			payment: billing.payment,
			paymentAmount: Utils.formatNumWithComma(Utils.BigNumber(billing.total).toFixed(2)),
			change: Utils.formatNumWithComma(Utils.BigNumber(billing.change).toFixed(2)),
			refund: Utils.formatNumWithComma(Utils.BigNumber(refund).toFixed(2)),
			unpaid: Utils.formatNumWithComma(subTotal.minus(serviceAdjustments).minus(billingPlanAdjustments).minus(billing.total).plus(billing.change).toFixed(2)),
			insuranceCompany: billingPlan.insuranceCompany,

			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier",
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }) + "/Patient",
			],
			signatureNames: [user.firstName + " " + user.lastName, patient.firstName + " " + patient.lastName],
		}
		return data
	}

	static generateHealthCarePlanReceiptCategoryData(patient, admission, billingPlan, billingStatements, billing, user, logo) {
		let subTotal = Utils.BigNumber(0)
		let serverAdjustments = Utils.BigNumber(0)
		let billingPlanAdjustments = ServerUtils.calculateBillingPlanAdjustments(billingPlan.billingPlanAdjustments)
		let refund = Utils.BigNumber(0)

		let data = {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.subTotal }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			invoiceIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceId }),
			receiptIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.receiptId }),
			adjustmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discount }),
			balanceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dischargeDate }),
			admissionIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.admission }),
			unpaidIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.unpaid }),
			changeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.change }),
			refundIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.refund }),
			collectFromIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.collectFrom }),

			logo: logo,
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : process.env.REACT_APP_ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : process.env.REACT_APP_BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : process.env.REACT_APP_ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : process.env.REACT_APP_TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : process.env.REACT_APP_TAX_ID,

			isReceipt: Utils.BigNumber(billing.total).gte(0) && !billing.debt,
			hasRefund: billingStatements.some((bs) => bs.refund),

			pid: patient.id,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientAddress: patient.address,
			receiptId: billing.id,
			printDate: Utils.formatDateTime(new Date()),
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			services: Object.entries(Utils.groupBy(billingStatements, "categoryName"))
				.map(([key, values]) => {
					let totals = values.reduce(
						(totalArray, cur) => {
							totalArray[0] = totalArray[0].plus(cur.charge)
							totalArray[1] = totalArray[1].plus(cur.adjustment)
							if (cur.refund) {
								totalArray[2] = totalArray[2].plus(cur.refund.amount)
							}
							return totalArray
						},
						[Utils.BigNumber(0), Utils.BigNumber(0), Utils.BigNumber(0)]
					)

					subTotal = subTotal.plus(totals[0])
					serverAdjustments = serverAdjustments.plus(totals[1])
					refund = refund.plus(totals[2])

					return {
						categoryName: key,
						amount: Utils.formatNumWithComma(totals[0].toFixed(2)),
						adjustments: Utils.formatNumWithComma(totals[1].toFixed(2)),
						balance: Utils.formatNumWithComma(totals[0].minus(totals[1]).toFixed(2)),
					}
				})
				.sort((a, b) => Utils.sort(a.categoryName, b.categoryName)),

			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			adjustments: Utils.formatNumWithComma(serverAdjustments.plus(billingPlanAdjustments).toFixed(2)),
			grandTotal: Utils.formatNumWithComma(subTotal.minus(serverAdjustments).minus(billingPlanAdjustments).toFixed(2)),
			payment: billing.payment,
			paymentAmount: Utils.formatNumWithComma(Utils.BigNumber(billing.total).toFixed(2)),
			change: Utils.formatNumWithComma(Utils.BigNumber(billing.change).toFixed(2)),
			refund: Utils.formatNumWithComma(Utils.BigNumber(refund).toFixed(2)),
			unpaid: Utils.formatNumWithComma(subTotal.minus(serverAdjustments).minus(billingPlanAdjustments).minus(billing.total).plus(billing.change).toFixed(2)),
			insuranceCompany: billingPlan.insuranceCompany,

			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier",
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }) + "/Patient",
			],
			signatureNames: [user.firstName + " " + user.lastName, patient.firstName + " " + patient.lastName],
		}
		return data
	}

	static generatePatientLabelData(patient, hn, room) {
		let target = {
			name: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientNum: patient.id,
			dob: Utils.formatDate(patient.dobtime),
			ageYear: Utils.calculateAge(patient.dobtime),
			ageMonth: Utils.calculateAgeMonth(patient.dobtime),
			hn: hn,
			displayRoom: !_.isEmpty(room),
			room: room,
		}
		return target
	}

	static generateCovid19ReportData(patient, lab, logo) {
		if (_.isEmpty(patient) || _.isEmpty(lab)) {
			return {}
		}
		let patientName = _.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
			? (patient.firstNameEng || patient.firstName) + " " + (patient.lastNameEng || patient.lastName)
			: patient.firstName + " " + patient.lastName
		let issuedBy = _.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
			? (lab.issuedBy.firstNameEng || lab.issuedBy.firstName) + " " + (lab.issuedBy.lastNameEng || lab.issuedBy.lastName)
			: lab.issuedBy.firstName + " " + lab.issuedBy.lastName
		let certifiedBy = _.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
			? (lab.certifiedBy.firstNameEng || lab.certifiedBy.firstName) + " " + (lab.certifiedBy.lastNameEng || lab.certifiedBy.lastName)
			: lab.certifiedBy.firstName + " " + lab.certifiedBy.lastName
		let approvedBy = _.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
			? (lab.approvedBy.firstNameEng || lab.approvedBy.firstName) + " " + (lab.approvedBy.lastNameEng || lab.approvedBy.lastName)
			: lab.approvedBy.firstName + " " + lab.approvedBy.lastName

		let data = {
			logo: logo,
			name: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? lab.location.parent.displayNameEng : lab.location.parent.displayName,
			branchName: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? lab.location.displayNameEng : lab.location.displayName,
			address: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? lab.location.address.displayNameEng : lab.location.address.displayName,
			tel: lab.location.address.tel,
			isPCR: lab.detail.analyte === ResourceAssistance.CONSTANT.SARS_COV_2_RNA_PCR,

			labNum: lab.id,
			issuedBy: issuedBy,
			issuedDate: Utils.formatDateTime(lab.issuedDateTime),
			certifiedBy: certifiedBy,
			certifiedDate: Utils.formatDateTime(lab.certifiedDateTime),
			approvedBy: approvedBy,
			approvedDate: Utils.formatDateTime(lab.approvedDateTime),
			patientId: patient.id,
			patientName: patientName,
			id: patient.identificationNumber,
			gender: this.getIntl().formatMessage({ id: Utils.convertEnum(patient.gender, false) }),
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			results: [
				{
					name: lab.detail.analyte,
					value: lab.detail.result,
					method: lab.detail.assay,
					isDisplay: true,
				},
				!_.isEmpty(lab.detail.antigenCOI) &&
					Utils.BigNumber(lab.detail.antigenCOI).isGreaterThanOrEqualTo(0) && {
						name: "Antigen COI",
						value: Utils.BigNumber(lab.detail.antigenCOI).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.antigenCOI).isGreaterThan(0),
					},
				!_.isEmpty(lab.detail.egene) &&
					Utils.BigNumber(lab.detail.egene).isGreaterThanOrEqualTo(0) && {
						name: ResourceAssistance.CONSTANT.EGENE,
						value: Utils.BigNumber(lab.detail.egene).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.egene).isGreaterThan(0),
					},
				!_.isEmpty(lab.detail.ngene) &&
					Utils.BigNumber(lab.detail.ngene).isGreaterThanOrEqualTo(0) && {
						name: "N gene:CT",
						value: Utils.BigNumber(lab.detail.ngene).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.ngene).isGreaterThan(0),
					},
				!_.isEmpty(lab.detail.n2Gene) &&
					Utils.BigNumber(lab.detail.n2Gene).isGreaterThanOrEqualTo(0) && {
						name: "N2 gene:CT",
						value: Utils.BigNumber(lab.detail.n2Gene).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.n2Gene).isGreaterThan(0),
					},
				!_.isEmpty(lab.detail.ogene) &&
					Utils.BigNumber(lab.detail.ogene).isGreaterThanOrEqualTo(0) && {
						name: "ORF1ab Gene:CT",
						value: Utils.BigNumber(lab.detail.ogene).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.ogene).isGreaterThan(0),
					},
				!_.isEmpty(lab.detail.sgene) &&
					Utils.BigNumber(lab.detail.sgene).isGreaterThanOrEqualTo(0) && {
						name: "S gene:CT",
						value: Utils.BigNumber(lab.detail.sgene).toFixed(3),
						isDisplay: Utils.BigNumber(lab.detail.sgene).isGreaterThan(0),
					},
			],
			comment: _.isEmpty(lab.detail.comment) ? undefined : lab.detail.comment,
		}
		return data
	}

	static generateClinicalRecordReportData = (patient, logo) => {
		let data = this.generateClinicalReportData(patient, logo)
		data = {
			...data,
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.opdClinicalRecord,
			}),
			treatment: patient.clinicalRecord.treatment,
		}
		return data
	}

	static generateAdmissionNoteReportData = (patient, logo) => {
		let data = this.generateClinicalReportData(patient, logo)
		data = {
			...data,
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.admission,
			}),
			admissionPlan: patient.clinicalRecord.admissionPlan,
		}
		return data
	}

	static generateMedicationSheetReportData(patient, medications, date, room, hn) {
		let data = {
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.medicationSheet,
			}),

			date: Utils.formatDate(date),
			room: room,

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			medications: medications,
		}

		return data
	}

	static generateVitalSignReportData(patient, vitalSigns, startDate, endDate, hn) {
		let dateBasedVitalSigns = []

		for (let i = 0; i <= Utils.calculateDaysBetween(startDate, endDate); i++) {
			let dateTime = Utils.generateDateFromLong(startDate, 0, 0, i, 0, 0, 0).getTime()
			dateBasedVitalSigns.push({
				date: Utils.formatDate(dateTime),
				day: i,
				vitalSigns: vitalSigns
					.filter((vitalSign) => _.isEqual(Utils.formatDate(vitalSign.dateTime), Utils.formatDate(dateTime)))
					.sort((a, b) => Utils.sort(a.dateTime, b.dateTime)),
			})
		}

		let data = {
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.vitalSign,
			}),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			dateBasedVitalSigns: dateBasedVitalSigns,
		}

		return data
	}

	static generateGlasgowComaScaleReportData(patient, glasgowComaScales, startDate, endDate, hn) {
		let dateBasedGlasgowComaScales = []

		for (let i = 0; i <= Utils.calculateDaysBetween(startDate, endDate); i++) {
			let dateTime = Utils.generateDateFromLong(startDate, 0, 0, i, 0, 0, 0).getTime()
			let curDateGlasgowComaScales = glasgowComaScales
				.filter((gcs) => _.isEqual(Utils.formatDate(gcs.dateTime), Utils.formatDate(dateTime)))
				.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
			if (!_.isEmpty(curDateGlasgowComaScales)) {
				dateBasedGlasgowComaScales.push({
					date: Utils.formatDate(dateTime),
					day: i,
					glasgowComaScales: curDateGlasgowComaScales.map((each) => {
						return Object.assign({}, each, {
							score: Utils.BigNumber(each.eyeOpening).plus(each.verbal).plus(each.motor).toNumber(),
						})
					}),
				})
			}
		}

		let data = {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.glasgowComaScale }),
			timeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.time }),
			eyeOpeningIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.eyeOpeningResponse }),
			verbalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.verbalResponse }),
			motorIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.motorResponse }),
			scoreIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.score }),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			glasgowComaScales: dateBasedGlasgowComaScales,
		}

		return data
	}

	static generatePupilSizeReportData(patient, pupileSizes, startDate, endDate, hn) {
		let dateBasedPupilSizes = []

		for (let i = 0; i <= Utils.calculateDaysBetween(startDate, endDate); i++) {
			let dateTime = Utils.generateDateFromLong(startDate, 0, 0, i, 0, 0, 0).getTime()
			let curDatePupileSizes = pupileSizes
				.filter((gcs) => _.isEqual(Utils.formatDate(gcs.dateTime), Utils.formatDate(dateTime)))
				.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
			if (!_.isEmpty(curDatePupileSizes)) {
				dateBasedPupilSizes.push({
					date: Utils.formatDate(dateTime),
					day: i,
					pupilSizes: curDatePupileSizes,
				})
			}
		}

		let data = {
			title: ResourceAssistance.CONSTANT.PUPIL_SIZE,
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pupilSize }),
			timeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.time }),
			leftEyePupilSizeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.leftEyePupilSizeMM }),
			leftEyeReactionToLightIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Message.leftEyeReactionToLight,
			}),
			rightEyePupilSizeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.rightEyePupilSizeMM }),
			rightEyeReactionToLightIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Message.rightEyeReactionToLight,
			}),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			pupilSizes: dateBasedPupilSizes,

			remark: this.getIntl().formatMessage({ id: ResourceAssistance.Remark.reactionToLight }),
		}

		return data
	}

	static generatePainScaleReportData(patient, painScales, startDate, endDate, hn) {
		let dateBasedPainScales = []

		for (let i = 0; i <= Utils.calculateDaysBetween(startDate, endDate); i++) {
			let dateTime = Utils.generateDateFromLong(startDate, 0, 0, i, 0, 0, 0).getTime()
			let curDatePainScales = painScales
				.filter((gcs) => _.isEqual(Utils.formatDate(gcs.dateTime), Utils.formatDate(dateTime)))
				.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
			if (!_.isEmpty(curDatePainScales)) {
				dateBasedPainScales.push({
					date: Utils.formatDate(dateTime),
					day: i,
					painScales: curDatePainScales,
				})
			}
		}

		let data = {
			title: ResourceAssistance.CONSTANT.PAIN_SCALE,
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.painScale }),
			timeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.time }),
			scoreIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.score }),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			painScales: dateBasedPainScales,

			remark: this.getIntl().formatMessage({ id: ResourceAssistance.Remark.reactionToLight }),
		}

		return data
	}

	static generateIntakeOutputReportData(patient, intakeOutputs, startDate, endDate, hn) {
		let dateBasedIntakeOutputs = []

		for (let i = 0; i <= Utils.calculateDaysBetween(startDate, endDate); i++) {
			let dateTime = Utils.generateDateFromLong(startDate, 0, 0, i, 0, 0, 0).getTime()
			let curDateIntakeOutputs = intakeOutputs
				.filter((io) => _.isEqual(Utils.formatDate(io.dateTime), Utils.formatDate(dateTime)))
				.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
			if (!_.isEmpty(curDateIntakeOutputs)) {
				dateBasedIntakeOutputs.push({
					date: Utils.formatDate(dateTime),
					day: i,
					intakeOutputs: curDateIntakeOutputs,
					totalIntake: curDateIntakeOutputs
						.reduce((total, cur) => {
							total = total.plus(cur.intakeAmount)
							return total
						}, Utils.BigNumber(0))
						.toNumber(),
					totalOutput: curDateIntakeOutputs
						.reduce((total, cur) => {
							total = total.plus(cur.outputAmount)
							return total
						}, Utils.BigNumber(0))
						.toNumber(),
					ioBalance: curDateIntakeOutputs
						.reduce((balance, cur) => {
							balance = balance.plus(cur.intakeAmount).minus(cur.outputAmount)
							return balance
						}, Utils.BigNumber(0))
						.toNumber(),
				})
			}
		}

		let data = {
			title: ResourceAssistance.CONSTANT.INTAKE_OUTPUT,
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.intakeOutput }),
			timeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.time }),
			intakeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.intake }),
			outputIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.output }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amountML }),
			noteIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.note }),
			totalIntakeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.totalIntake }),
			totalOutputIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.totalOutput }),
			ioBalanceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.ioBalance }),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			hn: hn,

			intakeOutputs: dateBasedIntakeOutputs,
		}

		return data
	}

	static generateFirstAssessmentReportData(patient) {
		let vs = patient.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))[0]

		let data = {
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.firstAssessment,
			}),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			dob: Utils.formatDate(patient.dobtime),
			age: Utils.calculateAge(patient.dobtime),
			hn: patient.clinicalRecord.hn,

			pi: Utils.replaceAllEmptyLines(patient.clinicalRecord.pi),

			pastIllnesses: patient.pastIllnesses,
			underlyingDisease: patient.underlyingDisease,

			temperature: vs.temperature,
			pr: vs.pr,
			rr: vs.rr,
			bp: vs.bp,
			oxygenSat: vs.oxygenSat,
			bw: patient.weight,
			ht: patient.height,
			bmi: Utils.BigNumber(patient.weight)
				.dividedBy(patient.height / 100)
				.dividedBy(patient.height / 100)
				.toFixed(2),

			diagnosis: patient.clinicalRecord.diagnosis,

			signatureDate: Utils.formatDateTime(new Date()),
			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }),
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.nurse }),
			],
		}

		return data
	}

	static generateLetterOfConsentReportData(patient) {
		let data = {
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.letterOfConsent,
			}),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			dob: Utils.formatDate(patient.dobtime),
			age: Utils.calculateAge(patient.dobtime),

			signatureDate: Utils.formatDateTime(new Date()),
		}

		return data
	}

	static generateDepressionAssessmentReportData(patient) {
		let data = {
			titleIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.depressionAssessment,
			}),

			pid: patient.id,
			id: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			dob: Utils.formatDate(patient.dobtime),
			age: Utils.calculateAge(patient.dobtime),

			signatureDate: Utils.formatDateTime(new Date()),
			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }),
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.nurse }),
			],
		}

		return data
	}

	static generatePayeeReportData = (filterStartDateTime, filterEndDateTime, dischargedOnly, role, payee, payeeOrders) => {
		let grandTotal = Utils.BigNumber(0)
		let data = {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.professionalServiceExpenseByUser }),
			startDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.startDate }),
			endDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.endDate }),
			dischargedOnlyIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discharged }),
			roleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.role }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			licenseNumIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.licenseNum }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			pidIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			healthCareIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.healthCare }),
			descriptionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			/* filter */
			startDate: Utils.formatDateTime(filterStartDateTime),
			endDate: Utils.formatDateTime(filterEndDateTime),
			dischargedOnly: dischargedOnly,
			role: role.displayName,
			name: payee.firstName + " " + payee.lastName,
			licenseNum: payee.licenseNum,

			/** orders */
			orders: payeeOrders.reduce((orders, order) => {
				if (order.doctorOrder) {
					let startDateTime =
						order.doctorOrder.startDateTime > filterStartDateTime ? order.doctorOrder.startDateTime : Utils.generateDateFromLong(filterStartDateTime).getTime()
					let endDateTime =
						order.doctorOrder.endDateTime > filterEndDateTime ? Utils.generateDateFromLong(filterEndDateTime).getTime() : order.doctorOrder.endDateTime
					for (let i = 0; i <= Utils.calculateDaysBetween(startDateTime, endDateTime); i++) {
						grandTotal = grandTotal.plus(order.doctorOrder.pricePerUnit)
						orders.push({
							pid: order.doctorOrder.clinicalRecord.admission.patient.id,
							healthCare: order.doctorOrder.clinicalRecord.admission.patient.healthCare,
							date: Utils.formatDate(Utils.generateDateFromLong(startDateTime, 0, 0, i).getTime()),
							description: order.doctorOrder.description,
							amount: Utils.formatNumWithComma(Utils.BigNumber(order.doctorOrder.pricePerUnit).toFixed(2)),
						})
					}
				} else if (order.nurseOrder) {
				}
				return orders
			}, []),

			grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
		}
		return data
	}

	static generateMedicalCertificateReportData(patient, certificate, location, logo) {
		return {
			documentNumIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.documentNum }),
			doctorNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.doctorName }),
			licenseNoIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.licenseNo }),
			certifiesThatIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.certifiesThat }),
			hasExaminedOnIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.hasExaminedOn }),
			symptomsIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.symptoms }),
			diagnosisIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.diagnosis }),
			investigationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.investigation }),
			bloodTestIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.bloodTest }),
			radiologyIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.radiology }),
			treatmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.treatment }),
			doctorOpinionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.doctorOpinion }),
			theAboveNamedPersonRequiresIntl: this.getIntl().formatMessage({
				id: ResourceAssistance.Hospitel.theAboveNamedPersonRequires,
			}),
			daysOfRestIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.daysOfRest }),
			fromIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.from }),
			toIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.to }),
			signatureIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.signature }),
			mdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.md }),

			title: certificate.location.parent.parent
				? ResourceAssistance.Report.intl.medicalCertificate.concat("(", certificate.location.systemCode.displayName, ")")
				: ResourceAssistance.Report.intl.medicalCertificate,
			titleIntl: certificate.location.parent.parent
				? this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.medicalCertificate }).concat("(", certificate.location.systemCode.displayName, ")")
				: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.medicalCertificate }),
			displayDepartmentCheckBox: !certificate.location.parent.parent,

			logo: logo,
			name: location
				? location.name
				: certificate.location.parent.parent
				? certificate.location.parent.parent.displayName
				: certificate.location.parent.displayName,
			branchName: location
				? location.branchName
				: certificate.location.parent.parent
				? certificate.location.parent.displayName
				: certificate.location.displayName,
			address: location
				? location.address
				: certificate.location.parent.parent
				? certificate.location.parent.address.displayName
				: certificate.location.address.displayName,
			tel: certificate.location.parent.parent ? certificate.location.parent.address.tel : certificate.location.address.tel,

			documentNum: certificate.id,
			doctorName: certificate.doctorName ? certificate.doctorName : "",
			licenseNo: certificate.doctorLicense,
			examinationStartDate: Utils.formatDate(certificate.examinationStartDateTime),
			examinationEndDate: Utils.formatDate(certificate.examinationEndDateTime),
			patientName: `${patient.title} ${patient.firstName ? patient.firstName : ""} ${patient.lastName ? patient.lastName : ""}`,
			symptoms: certificate.symptoms,
			diagnosis: certificate.diagnosis,
			investigation: certificate.investigation,
			isBloodTestChecked: certificate.bloodTestChecked,
			bloodTestDetails: certificate.bloodTestDetails,
			isRadiologyChecked: certificate.radiologyChecked,
			radiologyDetails: certificate.radiologyDetails,
			treatment: certificate.treatment,

			doctorOpinion: certificate.doctorOpinion,
			day: Utils.calculateDaysBetween(certificate.restStartDateTime, certificate.restEndDateTime),
			fromDate: Utils.formatDate(certificate.restStartDateTime),
			toDate: Utils.formatDate(certificate.restEndDateTime),

			printDate: Utils.formatDateTime(new Date().getTime()),
		}
	}

	static generateClaimFileData = (patient, clinicalRecord, file) => {
		let data = {
			title: this.getIntl().formatMessage({ id: ResourceAssistance.Message.claimFile }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			dobIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			ageIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.age }),
			healthCarePlanIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.healthCarePlan }),
			telIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.tel }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			identificationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.identification }),

			date: Utils.formatDateTime(clinicalRecord.creationDateTime),

			pid: patient.id,
			hn: clinicalRecord.hn,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			dob: Utils.formatDate(patient.dobtime),
			age: Utils.calculateAge(patient.dobtime),
			identification: patient.identificationNumber,
			healthCarePlan: patient.healthCare,
			tel: patient.tel,
			address: patient.address,

			file: "data:image/jpg;base64," + file,
		}
		return data
	}

	static generatePdfFileData = (file) => {
		if (_.isEmpty(file)) {
			return
		}
		let blob = Utils.base64ToBlob(file, "application/pdf")
		let blobUrl = URL.createObjectURL(blob)
		let data = {
			file: blobUrl,
		}
		return data
	}

	static generatePdfFileWithInfo = async (data, textToAdd) => {
		const raw = atob(data)
		const uint8Array = new Uint8Array(raw.length)
		for (let i = 0; i < raw.length; i++) {
			uint8Array[i] = raw.charCodeAt(i)
		}

		const pdfDoc = await PDFDocument.load(uint8Array)
		pdfDoc.registerFontkit(fontkit)
		const pages = pdfDoc.getPages()
		const firstPage = pages[0]

		const fontBytes = await fetch(sarabun).then((res) => res.arrayBuffer())
		const font = await pdfDoc.embedFont(fontBytes)
		let textSize = 12 // Start with an initial text size

		// Split the text into multiple lines
		const lines = textToAdd.split("\n")

		// Calculate the maximum text width and adjust the text size to fit the box
		let maxTextWidth = Math.max(...lines.map((line) => font.widthOfTextAtSize(line, textSize)))
		const maxAllowedWidth = 150 // Set the max width for the box
		const calculateMaxTextWidth = (size) => Math.max(...lines.map((line) => font.widthOfTextAtSize(line, size)))
		while (maxTextWidth > maxAllowedWidth && textSize > 6) {
			// Decrease the text size if text exceeds max width
			textSize -= 1
			maxTextWidth = calculateMaxTextWidth(textSize)
		}

		const lineHeight = textSize + 4
		const totalHeight = lines.length * lineHeight

		const padding = 2 // Padding around the text
		const topPadding = 8 // Increased padding to place text within the border
		const bottomPadding = 0

		const textX = firstPage.getWidth() - maxAllowedWidth - padding - 25 // Adjust X position for right alignment
		let textY = firstPage.getHeight() - padding - 24 // Adjust Y position to fit inside the box

		// Draw the rectangle starting at the top of the page
		firstPage.drawRectangle({
			x: textX - padding,
			y: textY - totalHeight,
			width: maxAllowedWidth + padding * 2,
			height: totalHeight + topPadding + bottomPadding,
			borderColor: rgb(0, 0, 0),
			borderWidth: 1,
		})

		// Draw the text inside the rectangle
		lines.forEach((line, index) => {
			firstPage.drawText(line, {
				x: textX,
				y: textY - index * lineHeight - 8,
				size: textSize,
				font: font,
				color: rgb(0, 0, 0),
			})
		})

		const pdfBytesModified = await pdfDoc.save()

		const binary = Array.from(pdfBytesModified)
			.map((byte) => String.fromCharCode(byte))
			.join("")
		const base64Modified = btoa(binary)

		return PrintableDataFactory.generatePdfFileData(base64Modified)
	}
}

export { PrintableDataFactory }
