import AirwayAssessment from "./constant/AirwayAssessment"
import Cardiovascular from "./constant/Cardiovascular"
import Renal from "./constant/Renal"
const Constant = {
	CONSTANT: {
		...AirwayAssessment,
		...Cardiovascular,
		...Renal,
		ABNORMAL: "A",
		ACCOUNTS_PAYABLE_AGING: "APA",
		ACCOUNTS_RECEIVABLE_AGING: "ARA",
		ADMIT_COVID19: "Admit Covid-19.",
		ADMIT_HOSPITEL: "Admit Hospitel.",
		BPM: "bpm",
		BRANCH: "BRANCH",
		CANCELLED: "CANCELLED",
		CLAIM: "Claim",
		CM: "cm",
		COMPLETED: "COMPLETED",
		COVID19_INFECTION: "Covid-19 Infection.",
		COVID19_NEGATIVE: "ผลการตรวจไม่พบเชื้อ Covid-19",
		COVID19_POSITIVE: "ผลการตรวจพบเชื้อ Covid-19",
		COVID_19_ANTIGEN: "COVID-19 Antigen",
		DAY: "day",
		DETECTED: "DETECTED",
		DOCTOR_OPITION_DEFAULT_AFTER: "ได้รับการรักษา ครบถ้วน 14 วันแล้ว สามารถไปทำงาน และ ใช้ชีวิตได้ตามปกติ",
		EGENE: "E gene:CT",
		FEMALE: "Female",
		GL_ASSETS_CODE: "1",
		GL_EQUITY_CODE: "3",
		GL_EXPENSES_CODE: "5",
		GL_LIABILITIES_CODE: "2",
		GL_REVENUES_CODE: "4",
		HOUR: "hr",
		IMMUNO_CHROMATOGRAPHY_ASSAY: "Immuno Chromatography Assay",
		IMMUNO_FLUORESCENCE_ASSAY: "Immuno Fluorescence Assay",
		INTAKE_OUTPUT: "Intake/Output",
		KG: "kg",
		MALE: "Male",
		MG_DL: "mg/dl",
		MINUTE: "min",
		ML: "mL",
		ML_MIN: "ml/min",
		MM: "mm",
		MMHG: "mmHg",
		NEGATIVE: "NEGATIVE",
		NONE: "-",
		NORMAL: "N",
		NOT_DETECTED: "NOT_DETECTED",
		NUMBER: "NO.",
		ONE_TIME: "One-Time",
		ORG: "ORG",
		OTHER: "Other",
		OVERLAY_HIDE: 0,
		OVERLAY_SHOW: 0,
		PAIN_SCALE: "Pain Scale",
		PCR: "Real-Time PCR",
		PCR_SARS_COV_2_RNA_PCR: "SARS-CoV-2-RNA-PCR",
		PCR_XPERT_XPRESS_SARS_COV_2: "Xpert-Xpress-SARS-CoV-2",
		PMGCARE: "PMGCare",
		PND1a: "P.N.D.1a",
		PND1aEx: "P.N.D.1a Ex",
		PND2: "P.N.D.2",
		PND2a: "P.N.D.2a",
		PND3: "P.N.D.3",
		PND3a: "P.N.D.3a",
		PND53: "P.N.D.53",
		POSITIVE: "POSITIVE",
		PRN: "PRN",
		PUPIL_SIZE: "Pupil Size",
		PX: "PX",
		REQUEST_COVID19_MEDICAL_CERTIFICATE: "ขอใบรับรองแพทย์ตรวจ Covid-19",
		SARS_COV_2_RNA_PCR: "SARS-CoV-2-RNA-PCR",
		SAT_CODE: "SAT Code",
		STAT: "STAT",
		SUPPORTIVE_TREATMENT_FOR_COVID: "Supportive treatment for Covid-19",
		TAX: 7,
		TESTED: "TESTED",
		XPERT_XPRESS_SARS_COV_2: "Xpert-Xpress-SARS-CoV-2",
	},
}

export default Constant
