import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPSRequests, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PS_CHART_OF_ACCOUNTS,
	SET_PS_CHART_OF_ACCOUNTS_LOADING,
	SET_PS_GENERAL_LEDGER_DISPLAY,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PSModalGeneralLedgerDetails from "./PSModalGeneralLedgerDetails"
import { Card } from "antd"

class PSModalGeneralLedger extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.PS.chartOfAccounts)) {
			this.loadChartOfAccounts()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.modalGeneralLedger.updateGL)) {
				if (this.props.PS.isPaymentPlanCompleteTab) {
					this.updateFinancialOperationGL()
				} else {
					this.updateInvoiceGL()
				}
			}
		}
	}

	loadChartOfAccounts() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_CHART_OF_ACCOUNTS, Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName")))
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PS_CHART_OF_ACCOUNTS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PS_CHART_OF_ACCOUNTS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PS_CHART_OF_ACCOUNTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PS_GENERAL_LEDGER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.PS.modalGeneralLedger.selectedGL.GL) {
			return true
		}
	}

	updateInvoiceGL() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.updateInvoiceGL,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				invoices: this.props.PS.selectedGroup.group[1].map((each) => {
					return {
						...each,
						id: each.id,
						paymentPlanId: each.paymentPlan.id,
						accountingCode: this.props.PS.modalGeneralLedger.selectedGL.GL.fullCode,
						accountingChartName: this.props.PS.modalGeneralLedger.selectedGL.GL.displayName,
						accountingChartDescription: this.props.PS.modalGeneralLedger.selectedGL.GL.description,
						accountingTransDescr: Utils.trim(this.props.PS.modalGeneralLedger.description),
					}
				}),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	updateFinancialOperationGL() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.updateFinancialOperationGL,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				financialRequests: [
					{
						id: this.props.PS.selectedCompletePaymentPlan.paymentPlan.financialRequest.id,
						accountingCode: this.props.PS.modalGeneralLedger.selectedGL.GL.fullCode,
						accountingChartName: this.props.PS.modalGeneralLedger.selectedGL.GL.displayName,
						accountingChartDescription: this.props.PS.modalGeneralLedger.selectedGL.GL.description,
						accountingTransDescr: Utils.trim(this.props.PS.modalGeneralLedger.description),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let selectedGL = this.props.PS.modalGeneralLedger.selectedGL.GL
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.paymentSlip.modalGeneralLedger.updateGL)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.save)}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{selectedGL.fullCode}</p>
				<p>{selectedGL.displayName}</p>
				<p>{selectedGL.description}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.generalLedger)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PSModalGeneralLedgerDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPSGeneralLedgerDisplay,
	login: state.login,
	PS: state.accounting.paymentSlip,
	warning: state.modal.warning,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalGeneralLedger))
