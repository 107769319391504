import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportClinicalRecordDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				icds: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} displayFax={false} displayTaxNum={false} displayBranch={false} />

						{/*Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Date: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.date}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.identificationIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.identification}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.nameIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.dobIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.ageIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.genderIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.gender}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.nationIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.nation}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.raceIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.race}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.maritalStatusIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.maritalStatus}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.healthCarePlanIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.healthCarePlan}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.telIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.patientTel}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.addressIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.patientAddress}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.emergencyContactIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.emergencyContactName}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.telIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.emergencyContactTel}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalLine} />

									{/* Patient History */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.allergiesIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.allergies}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.underlyingDiseaseIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.underlyingDisease}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>{this.state.data.pastIllnessesIntl}: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.pastIllnesses}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/*  */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>ดื่มสุรา</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>สูบบุหรี่</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>ตั้งครรภ์</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>วัคซีน</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>ครบ</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{this.state.data.test ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>ไม่ครบ</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Health */}
						<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
							<View style={ResourceAssistance.Report.styles.verticalMiddleLine} />
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
								{/* Vital Sign Header */}
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 12 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.temperature]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>T.(C)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pr]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>PR./min</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rr]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>RR./min</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bp]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BP.(mmHg)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bw]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BW.(kg)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.ht]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>HT.(cm)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bmi]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BMI</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.oxygen]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>O{ResourceAssistance.Symbol[2]}Sat</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />

								{/* Vital Sign */}
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 12 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.temperature]}>
										<Text>{this.state.data.temperature}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pr]}>
										<Text>{this.state.data.pr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rr]}>
										<Text>{this.state.data.rr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bp]}>
										<Text>{this.state.data.bp}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bw]}>
										<Text>{this.state.data.bw}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.ht]}>
										<Text>{this.state.data.ht}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bmi]}>
										<Text>{this.state.data.bmi}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.oxygen]}>
										<Text>{this.state.data.oxygenSat}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.separator} />

								{/* CC. */}
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>CC.: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
										<Text>{this.state.data.cc}</Text>
									</View>
								</View>

								{/* PI. */}
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>PI.: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
										<Text>{this.state.data.pi}</Text>
									</View>
								</View>

								{/* Physical Examination */}
								<View wrap={false}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>GA.: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.ga}</Text>
										</View>
									</View>

									{/* HEENT */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>HEENT</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.heentChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.heentChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.heentNote}</Text>
										</View>
									</View>

									{/* Heart */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Heart</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.heartChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.heartChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.heartNote}</Text>
										</View>
									</View>

									{/* Lung */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Lung</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.lungChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.lungChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.lungNote}</Text>
										</View>
									</View>

									{/* Abdomen */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Abdomen</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.abdomenChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.abdomenChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.abdomenNote}</Text>
										</View>
									</View>

									{/* Back */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Back</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.backChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.backChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.backNote}</Text>
										</View>
									</View>

									{/* Extremities */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Extremities</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.extremitiesChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.extremitiesChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.extremitiesNote}</Text>
										</View>
									</View>

									{/* Neuro */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.physicalExaminationWidth, { fontStyle: "bold" }]}>
											<Text>Neuro</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{this.state.data.neuroChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>N</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text style={[ResourceAssistance.Report.styles.textIcon]}>
												{!this.state.data.neuroChecked ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
											</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto]}>
											<Text>A: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.neuroNote}</Text>
										</View>
									</View>

									{/* Others */}
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Others: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
											<Text>{this.state.data.others}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.separator} />
								</View>

								{/* Diagnosis */}
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>{this.state.data.diagnosisIntl}: </Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
										<Text>{this.state.data.diagnosis}</Text>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader]}>
										<Text>{this.state.data.icdIntl}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<View style={[ResourceAssistance.Report.styles.row, { minHeight: "30%" }]}>
									<View style={[ResourceAssistance.Report.styles.col, { fontStyle: "italic", paddingHorizontal: 10, justifyContent: "flex-start" }]}>
										{this.state.data.icds.map((icd) => (
											<Text>
												{icd.code} - {icd.description} - {icd.level}
											</Text>
										))}
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader]}>
										<Text>{this.state.data.treatmentIntl}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, { fontStyle: "italic", paddingHorizontal: 10 }]}>
										<Text>{this.state.data.treatment}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						<Signature numOfSignature={1} signatureDate={this.state.data.signatureDate} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 100,
	},
	infoHeader: {
		fontStyle: "bold",
		justifyContent: "flex-start",
	},
	infoBody: {
		fontStyle: "italic",
		marginRight: 10,
	},
	itemRow: {
		marginBottom: 10,
	},
	dashBottom: {
		borderBottomStyle: "dashed",
		borderBottomWidth: 1,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	physicalExaminationWidth: {
		minWidth: "24%",
		maxWidth: "24%",
	},
	temperature: {
		minWidth: "8%",
		maxWidth: "8%",
	},
	pr: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	rr: {
		minWidth: "14%",
		maxWidth: "14%",
	},
	bp: {
		minWidth: "19%",
		maxWidth: "19%",
	},
	bw: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	ht: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	bmi: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	oxygen: {
		minWidth: "10%",
		maxWidth: "10%",
	},
})

export default ReportClinicalRecordDocument
