import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setIPDPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_IPD_PATIENTS_LOADING, SET_HOSPITEL_IPD_REGISTER_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import IPDModalRegisterDetails from "./IPDModalRegisterDetails"

class IPDModalRegister extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDModalRegister",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_IPD_REGISTER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.ipd.selectedPatient.patient, {
			...this.props.ipd.selectedPatient.patient,
			photo: this.props.ipd.modalRegister.photo,
			title: Utils.trim(this.props.ipd.modalRegister.title),
			firstName: Utils.trim(this.props.ipd.modalRegister.firstName),
			lastName: Utils.trim(this.props.ipd.modalRegister.lastName),
			firstNameEng: Utils.trim(this.props.ipd.modalRegister.firstNameEng),
			lastNameEng: Utils.trim(this.props.ipd.modalRegister.lastNameEng),
			identificationNumber: Utils.trim(this.props.ipd.modalRegister.identificationNumber),
			idType: Utils.trim(this.props.ipd.modalRegister.idType),
			marriage: Utils.trim(this.props.ipd.modalRegister.marriage),
			career: Utils.trim(this.props.ipd.modalRegister.career),
			nation: Utils.trim(this.props.ipd.modalRegister.nation),
			race: Utils.trim(this.props.ipd.modalRegister.race),
			gender: Utils.trim(this.props.ipd.modalRegister.gender),
			dobtime: this.props.ipd.modalRegister.dob,
			tel: Utils.trim(this.props.ipd.modalRegister.tel),
			telSecondary: Utils.trim(this.props.ipd.modalRegister.telSecondary),
			telTertiary: Utils.trim(this.props.ipd.modalRegister.telTertiary),
			address: Utils.trim(this.props.ipd.modalRegister.address),
			height: Number(this.props.ipd.modalRegister.height),
			weight: Number(this.props.ipd.modalRegister.weight),
			underlyingDisease: Utils.trim(this.props.ipd.modalRegister.underlyingDisease),
			allergies: Utils.trim(this.props.ipd.modalRegister.allergies),
			pastIllnesses: Utils.trim(this.props.ipd.modalRegister.pastIllnesses),
			pastSurgicalHistory: Utils.trim(this.props.ipd.modalRegister.pastSurgicalHistory),
			familyHealthHistory: Utils.trim(this.props.ipd.modalRegister.familyHealthHistory),
			relativeFirstName: Utils.trim(this.props.ipd.modalRegister.relativeFirstName),
			relativeLastName: Utils.trim(this.props.ipd.modalRegister.relativeLastName),
			relationship: Utils.trim(this.props.ipd.modalRegister.relationship),
			relativeTel: Utils.trim(this.props.ipd.modalRegister.relativeTel),
			relativeAddress: Utils.trim(this.props.ipd.modalRegister.relativeAddress),
		})

		return (
			Utils.BigNumber(this.props.ipd.modalRegister.dob).isGreaterThanOrEqualTo(Utils.generateDate().getTime()) ||
			_.isEmpty(this.props.ipd.modalRegister.title) ||
			_.isEmpty(this.props.ipd.modalRegister.gender) ||
			_.isEqual(this.props.ipd.selectedPatient.patient, target)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.registration.registerPatient,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				patients: [
					{
						...this.props.ipd.selectedPatient.patient,
						id: this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.id : 0,
						photo: this.props.ipd.modalRegister.photo,
						title: Utils.trim(this.props.ipd.modalRegister.title),
						firstName: Utils.trim(this.props.ipd.modalRegister.firstName),
						lastName: Utils.trim(this.props.ipd.modalRegister.lastName),
						firstNameEng: Utils.trim(this.props.ipd.modalRegister.firstNameEng),
						lastNameEng: Utils.trim(this.props.ipd.modalRegister.lastNameEng),
						identificationNumber: Utils.trim(this.props.ipd.modalRegister.identificationNumber),
						idType: Utils.trim(this.props.ipd.modalRegister.idType),
						marriage: Utils.trim(this.props.ipd.modalRegister.marriage),
						career: Utils.trim(this.props.ipd.modalRegister.career),
						nation: Utils.trim(this.props.ipd.modalRegister.nation),
						race: Utils.trim(this.props.ipd.modalRegister.race),
						gender: Utils.trim(this.props.ipd.modalRegister.gender),
						tel: Utils.trim(this.props.ipd.modalRegister.tel),
						telSecondary: Utils.trim(this.props.ipd.modalRegister.telSecondary),
						telTertiary: Utils.trim(this.props.ipd.modalRegister.telTertiary),
						address: Utils.trim(this.props.ipd.modalRegister.address),
						dob: this.props.ipd.modalRegister.dob,
						height: this.props.ipd.modalRegister.height,
						weight: this.props.ipd.modalRegister.weight,
						underlyingDisease: Utils.trim(this.props.ipd.modalRegister.underlyingDisease),
						allergies: Utils.trim(this.props.ipd.modalRegister.allergies),
						pastIllnesses: Utils.trim(this.props.ipd.modalRegister.pastIllnesses),
						pastSurgicalHistory: Utils.trim(this.props.ipd.modalRegister.pastSurgicalHistory),
						familyHealthHistory: Utils.trim(this.props.ipd.modalRegister.familyHealthHistory),
						relativeFirstName: Utils.trim(this.props.ipd.modalRegister.relativeFirstName),
						relativeLastName: Utils.trim(this.props.ipd.modalRegister.relativeLastName),
						relationship: Utils.trim(this.props.ipd.modalRegister.relationship),
						relativeTel: Utils.trim(this.props.ipd.modalRegister.relativeTel),
						relativeAddress: Utils.trim(this.props.ipd.modalRegister.relativeAddress),
					},
				],
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.patients)) {
				if (
					this.props.ipd.patientTable.filtered.length > 0 &&
					res.data.patients[0].creationDateTime < this.props.ipd.patientTable.filtered[0].creationDateTime
				) {
					this.props.setIPDPatients(res.data.patients)
				} else {
					let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
					patients.push(res.data.patients[0])
					this.props.setIPDPatients(patients)
				}
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.registerPatient)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<IPDModalRegisterDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.ipd.isRegisterPatientDisplay,
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setIPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalRegister)
