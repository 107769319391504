import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PS_SUPPLIERS, SET_PS_SUPPLIER_LOADING } from "~/redux/type"
import PSModalNewSearchFilter from "./PSModalNewSearchFilter"
import PSModalNewInvoices from "./PSModalNewInvoice"
import PSModalNewInvoiceAction from "./PSModalNewInvoiceAction"
import PSModalNewPendingInvoice from "./PSModalNewPendingInvoice"

class PSModalNewDetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PS.suppliers)) {
			this.loadSuppliers()
		}
	}

	loadSuppliers() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getSuppliers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_SUPPLIERS, res.data.suppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				<PSModalNewSearchFilter />
				<PSModalNewInvoices />
				<PSModalNewInvoiceAction />
				<PSModalNewPendingInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PSModalNewDetails)
