import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

const MEDICATION_LABEL_WIDTH = process.env.REACT_APP_MEDICATION_LABEL_WIDTH
const MEDICATION_LABEL_HEIGHT = process.env.REACT_APP_MEDICATION_LABEL_HEIGHT
const LOGO_REPLACE_SPACE = process.env.REACT_APP_MEDICATION_LABEL_LOGO_REPLACE_SPACE

class LabelMedicationDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				lots: [],
				dose: "",
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={Utils.isDevMode() ? ResourceAssistance.Report.size.page.ML : [MEDICATION_LABEL_WIDTH, MEDICATION_LABEL_HEIGHT]}
						style={[ResourceAssistance.Report.styles.pageLabel, styles.page]}
					>
						{this.state.data.logo ? (
							<ReportTitle
								data={this.state.data}
								displayFax={false}
								displayTaxNum={false}
								displayBranch={false}
								displayTitleSeparator={false}
								styles={{
									orgImg: {
										width: LOGO_REPLACE_SPACE,
										height: LOGO_REPLACE_SPACE,
										objectFit: "contain",
									},
								}}
							/>
						) : (
							<View style={[ResourceAssistance.Report.styles.row, { marginBottom: Utils.isDevMode() ? 40 : LOGO_REPLACE_SPACE }]}></View>
						)}

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>PID: {this.state.data.pid}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.patientName}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.description}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.dose.replace(/\r?\n|\r/g, " ")}</Text>
							</View>
						</View>
						{!_.isEmpty(this.state.data.warnings) && (
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 8 }]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>* {this.state.data.warnings.replace(/\r?\n|\r/g, " ")}</Text>
								</View>
							</View>
						)}

						{this.state.data.lots
							.map((lot) => {
								return (
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.col]}>
											<Text>Lot: {lot.lotNum}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col]}>
											<Text>
												{this.state.data.expiredIntl}/Expired: {Utils.formatDate(lot.expiredDateTime)}
											</Text>
										</View>
									</View>
								)
							})
							.concat(
								<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
									<View style={[ResourceAssistance.Report.styles.col]}></View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>
											{this.state.data.total} {this.state.data.unit}
										</Text>
									</View>
								</View>
							)}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}></View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{Utils.formatDateTime(moment().valueOf())}</Text>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		fontSize: 10,
		justifyContent: "unset",
	},
})

export default LabelMedicationDoc
