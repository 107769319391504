import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES,
	SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT,
	SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES,
	SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE,
	SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT,
	SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY,
	SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class IPDModalRegisterHealth extends React.Component {
	constructor(props) {
		super(props)

		this.onHeight = this.onHeight.bind(this)
		this.onWeight = this.onWeight.bind(this)
		this.onUnderlyingDisease = this.onUnderlyingDisease.bind(this)
		this.onAllergies = this.onAllergies.bind(this)
		this.onPastIllnesses = this.onPastIllnesses.bind(this)
		this.onPastSurgicalHistory = this.onPastSurgicalHistory.bind(this)
		this.onFamilyHealthHistory = this.onFamilyHealthHistory.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedPatient.index !== -1) {
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT, this.props.ipd.selectedPatient.patient.height)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT, this.props.ipd.selectedPatient.patient.weight)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE, this.props.ipd.selectedPatient.patient.underlyingDisease)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES, this.props.ipd.selectedPatient.patient.allergies)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES, this.props.ipd.selectedPatient.patient.pastIllnesses)
			this.props.setValue(SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL, this.props.ipd.selectedPatient.patient.pastSurgicalHistory)
			this.props.setValue(SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY, this.props.ipd.selectedPatient.patient.familyHealthHistory)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT, "")
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY, ResourceAssistance.CONSTANT.NONE)
	}

	onHeight(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_HEIGHT, event.target.value)
	}

	onWeight(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_WEIGHT, event.target.value)
	}

	onUnderlyingDisease(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_UNDERLYING_DISEASE, event.target.value)
	}

	onAllergies(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_ALLERGIES, event.target.value)
	}

	onPastIllnesses(event) {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_PAST_ILLNESSES, event.target.value)
	}

	onPastSurgicalHistory(event) {
		this.props.setValue(SET_REGISTER_MODAL_REGISTER_PAST_SURGICAL, event.target.value)
	}

	onFamilyHealthHistory(event) {
		this.props.setValue(SET_REGISTER_MODAL_REGISTER_FAMILY_HISTORY, event.target.value)
	}

	render() {
		return (
			<fieldset className={"space-top-each-row-parent"}>
				<legend>{translate(ResourceAssistance.Hospitel.health)}</legend>
				<Row>
					<Col>
						<GInput
							required
							styles={{ input: { textAlign: "center" } }}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							placeholder={translate(ResourceAssistance.Hospitel.height)}
							suffix={ResourceAssistance.CONSTANT.CM}
							value={this.props.ipd.modalRegister.height}
							onChange={this.onHeight}
						/>
					</Col>
					<Col>
						<GInput
							required
							styles={{ input: { textAlign: "center" } }}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							placeholder={translate(ResourceAssistance.Hospitel.weight)}
							suffix={ResourceAssistance.CONSTANT.KG}
							value={this.props.ipd.modalRegister.weight}
							onChange={this.onWeight}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Hospitel.underlyingDisease)}
							value={this.props.ipd.modalRegister.underlyingDisease}
							onChange={this.onUnderlyingDisease}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Hospitel.allergies)}
							value={this.props.ipd.modalRegister.allergies}
							onChange={this.onAllergies}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Hospitel.pastIllnesses)}
							value={this.props.ipd.modalRegister.pastIllnesses}
							onChange={this.onPastIllnesses}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.pastSurgicalHistory)}
							value={this.props.ipd.modalRegister.pastSurgicalHistory}
							onChange={this.onPastSurgicalHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.familyHealthHistory)}
							value={this.props.ipd.modalRegister.familyHealthHistory}
							onChange={this.onFamilyHealthHistory}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalRegisterHealth))
